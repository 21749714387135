import React from 'react'

const Czech: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
      <circle cx='256' cy='256' r='256' fill='#F0F0F0' />
      <path
        d='M233.7 256c0 0-158.6 181.1-158.8 181C121.3 483.3 185.3 512 256 512c141.4 0 256-114.6 256-256H233.7z'
        fill='#D80027'
      />
      <path
        d='M75 75c-100 100-100 262.1 0 362C116.3 395.7 156 356 256 256L75 75z'
        fill='#0052B4'
      />
    </svg>
  )
}

export default Czech
