import { action } from 'typesafe-actions'
import {
  LOAD_SPECIAL_OFFER_SUCCESS,
  CLEAN_UP_SPECIAL_OFFER,
  SAVE_SPECIAL_OFFER,
} from './constants'
import { IMenuItem } from './gql/types'

export const loadSpecialOfferSuccess = (args: {
  [restaurantId: string]: { specialOffer: IMenuItem[] }
}) => action(LOAD_SPECIAL_OFFER_SUCCESS, args)

export const saveSpecialOffer = (args: {
  restaurantId: string
  menuItems: IMenuItem[]
}) => action(SAVE_SPECIAL_OFFER, args)

export const deleteOtherSpecialOffersThanFromTheseRestaurants = (args: {
  restaurantIds: string[]
}) => action(CLEAN_UP_SPECIAL_OFFER, args)
