import styled, { css } from 'styled-components'

export const Wrap = styled.div``

type LangProps = {
  isSelected: boolean
  disabled: boolean
}
export const LangButton = styled.button<LangProps>`
  background: none;
  border: 0;
  outline: none;

  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin: 8px;

  transition: all 200ms;

  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: 0 0 8px #777777;
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;

      &:hover {
        transform: translateY(-3px) scale(1.1);
      }
    `}
`
