import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { GoogleApiWrapper, Map, IProvidedProps } from 'google-maps-react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NearMe from '@material-ui/icons/NearMe'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { selectRestaurants } from 'src/data/Restaurant/selectors'
import { IRestaurant } from 'src/data/Restaurant/gql/types'

import * as S from './styled'

import renderMapMarkers from './mapMarkers'
import Button from 'src/components/Button'
import config from 'src/config'
import LocationLinks from './locationLinks'
import { MapState } from '..'

type MapPosition = google.maps.LatLngLiteral

interface IProps extends IProvidedProps {
  defaultLocation?: MapPosition
  onLocationSelected: (position: Position) => void
  onBoundsChanged: (args: MapState) => void
  onCancel: () => void
}

const MapContainer: React.FC<IProps> = ({
  google,
  defaultLocation,
  onLocationSelected,
  onBoundsChanged: onBoundsChangedCallback,
  onCancel,
}) => {
  const [t] = useTranslation()
  const restaurants = useSelector(selectRestaurants)
  const history = useHistory()

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined)

  const [perimeter, setPerimeter] = useState({ radius: 0, visible: false })
  const [isLocatorVisible, setIsLocatorVisible] = useState(false)
  const [isLocating, setIsLocating] = useState(false)

  useEffect(() => {
    // set locator button visibility
    const setStateAsync = async () => {
      if (!navigator.geolocation) {
        return
      }
      if (navigator.permissions) {
        const { state } = await navigator.permissions.query({
          name: 'geolocation',
        })
        if (state !== 'denied') {
          setIsLocatorVisible(true)
        }
      } else {
        // permission status is not available
        // so the user has safari of permissions are denied
        setIsLocatorVisible(true)
      }
    }
    setStateAsync()
  }, [])

  const setMapCenter = useCallback(
    (latlng: google.maps.LatLngLiteral) => {
      if (map) {
        console.log('setMapCenter', latlng)
        map.setCenter(latlng)
        map.setZoom(15)
      }
    },
    [map]
  )

  const onBoundChanged = useCallback(
    (mapElement: google.maps.Map<Element>) => {
      const bounds = mapElement.getBounds()
      const center = mapElement.getCenter()
      onBoundsChangedCallback({ bounds: bounds || undefined, center })
    },
    [onBoundsChangedCallback]
  )

  const onZoomChanged = useCallback((mapElement: google.maps.Map<Element>) => {
    const zoom = mapElement.getZoom()
    const diameterInPx = 300 // set the circle diameter
    // calculate distance in meters, 14/4/300 = 1 px in meters
    const radiusInMeters = ((14 / 4) * 2 ** (22 - zoom) * diameterInPx) / 300

    setPerimeter((state) => ({
      radius: radiusInMeters,
      visible: radiusInMeters < 10000,
    }))
  }, [])

  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (!map) {
        return
      }
      const mapCenter = map.getCenter()
      const position: Position = {
        coords: {
          accuracy: 0,
          altitude: 0,
          altitudeAccuracy: 0,
          heading: 0,
          speed: 0,
          latitude: mapCenter.lat(),
          longitude: mapCenter.lng(),
        },
        timestamp: new Date().getTime(),
      }
      onLocationSelected(position)
      onCancel()
    },
    [map, onCancel, onLocationSelected]
  )

  const onLocatorClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      setIsLocating(true)
      navigator.geolocation &&
        navigator.geolocation.getCurrentPosition(
          // on success
          (position) => {
            setMapCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setIsLocating(false)
            // propagate to parent
            onLocationSelected(position)
          },
          // on error
          (err) => {
            console.error(err)
            setIsLocating(false)
          }
        )
    },
    [onLocationSelected, setMapCenter]
  )

  // center to last location or to Prague to show some places
  const initialCenter: MapPosition =
    defaultLocation && defaultLocation.lat && defaultLocation.lng
      ? defaultLocation
      : {
          lat: 50.08305140164937,
          lng: 14.434561256123732,
        }

  const memoizedMarkers = useMemo(
    () => renderMapMarkers(restaurants as IRestaurant[], history),
    [restaurants, history]
  )

  return (
    <S.Wrap>
      <Map
        google={google}
        zoom={14}
        initialCenter={initialCenter}
        streetViewControl={false}
        clickableIcons={false}
        fullscreenControl={false}
        mapTypeControl={false}
        gestureHandling={'greedy'}
        minZoom={7}
        onReady={(mapProps, map, event) => {
          if (map) {
            // set map instance to state
            setMap(map)
            // set the circle diameter
            onZoomChanged(map)
            // propagate bounds change
            onBoundChanged(map)
          }
        }}
        onDragstart={(mapProps, map, event) => {
          if (map) {
            setPerimeter((state) => ({ ...state, visible: false }))
          }
        }}
        onDragend={(mapProps, map, event) => {
          if (map) {
            onBoundChanged(map)
            setPerimeter((state) => ({
              ...state,
              visible: state.radius < 10000,
            }))
          }
        }}
        onZoomChanged={(mapProps, map, event) => {
          if (map) {
            onBoundChanged(map)
            onZoomChanged(map)
          }
        }}
        styles={[
          {
            stylers: [
              {
                saturation: -10,
              },
            ],
          },
        ]}
      >
        {memoizedMarkers}
      </Map>

      <S.PinWrap />

      <S.Perimeter visible={perimeter.visible}>
        <S.PerimeterRange>{perimeter.radius} m</S.PerimeterRange>
      </S.Perimeter>

      <S.MainButtonWrap>
        <Button onClick={onButtonClick} variant={'primary'} size={'medium'}>
          {t('map.mainButton')}
        </Button>
      </S.MainButtonWrap>

      <S.TopBar>
        <S.ButtonBack onClick={onCancel}>
          <ArrowBack />
        </S.ButtonBack>
        <LocationLinks setMapCenter={setMapCenter} />
      </S.TopBar>

      {isLocatorVisible && (
        <S.ButtonLocator onClick={onLocatorClick}>
          {isLocating ? <CircularProgress size={20} /> : <NearMe />}
        </S.ButtonLocator>
      )}
    </S.Wrap>
  )
}

export default GoogleApiWrapper({
  apiKey: config.REACT_APP_GOOGLE_MAPS_KEY,
})(MapContainer)
