import { TAction } from '../store'
import { loadUserSuccess } from './actions'
import * as userApi from './api'
import { signInVariables } from './gql/__generated__/signIn'
import {
  setLocalStorage,
  removeLocalStorage,
  LocalStorageKeys,
} from 'src/utils/localStorage'
import { resetLogin } from '../globalActions'
import { signUpVariables } from './gql/__generated__/signUp'
import { generateDeviceUid } from 'src/utils/notificationsUtils'

/**
 * Verify user credentials. Login if correct, throw an error otherwise
 */
export const handleSignIn: TAction<void, signInVariables> = (
  vars: signInVariables
) => async (dispatch) => {
  try {
    const { token, user } = await userApi.requestSignIn(vars)
    // todo: store tokens in better place then local storage (eg. HTTP only cookie)
    setLocalStorage(LocalStorageKeys.USER_TOKEN, token)
    // save last login date to propose login instead of registration next time
    setLocalStorage(LocalStorageKeys.LAST_LOGIN_DATE, new Date().toUTCString())
    dispatch(loadUserSuccess(user))
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 * Creates new user account. Login if correct, throw an error otherwise
 */
export const handleEmailSignUp: TAction<void, signUpVariables> = (
  vars
) => async (dispatch) => {
  try {
    const { token, user } = await userApi.requestSignUp(vars)
    // todo: store tokens in better place then local storage (eg. HTTP only cookie)
    setLocalStorage(LocalStorageKeys.USER_TOKEN, token)
    // save last login date to propose login instead of registration next time
    setLocalStorage(LocalStorageKeys.LAST_LOGIN_DATE, new Date().toUTCString())
    dispatch(loadUserSuccess(user))
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 * Loads and saves current user data
 */
export const handleLoadCurrentUser: TAction<void> = () => async (dispatch) => {
  try {
    const user = await userApi.requestCurrentUser()
    dispatch(loadUserSuccess(user))
  } catch (e) {
    console.error(e)
  }
}

/**
 * Verify currently logged in user credentials
 */
export const handleVerifyUser: TAction<void> = () => async (dispatch) => {
  try {
    // verify the token
    const token = await userApi.requestRefreshToken()
    setLocalStorage(LocalStorageKeys.USER_TOKEN, token)
    // fetch the current user
    const user = await userApi.requestCurrentUser()
    dispatch(loadUserSuccess(user))
  } catch (e) {
    dispatch(handleLogout())
    console.error(e)
  }
}

/**
 * Logout current user
 */
export const handleLogout: TAction<void> = () => async (dispatch) => {
  dispatch(resetLogin())
  removeLocalStorage(LocalStorageKeys.USER_TOKEN)
}

/**
 * Set FCM token on server
 * Every user can have multiple devices, so register this device under unique ID
 */
export const handleSendFCMToken: TAction<boolean, { token: string }> = ({
  token,
}) => async (dispatch, getState) => {
  try {
    // 1 - get array of user's registred devices
    const fcmDevices = getState().user.user?.notifications.fcmDevices
    // 2 - is this device in the array? Return its ID. Otherwise create a new ID
    const deviceUid = generateDeviceUid(fcmDevices || [])
    // 3 - set the FCM token with this ID on the server
    const result = await userApi.requestSendFCMToken({ token, deviceUid })
    // 4 - reload current user
    await dispatch(handleLoadCurrentUser())
    return result
  } catch (e) {
    console.error(e)
    return false
  }
}

export const handleEnableEmailNotifications: TAction<boolean> = () => async (
  dispatch
) => {
  const result = await userApi.requestEnableEmailNotifications({
    isEnabled: true,
  })
  await dispatch(handleLoadCurrentUser())
  return result
}
