import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'
import { IMenuItem } from './gql/types'
import {
  LOAD_SPECIAL_OFFER_SUCCESS,
  CLEAN_UP_SPECIAL_OFFER,
  SAVE_SPECIAL_OFFER,
} from './constants'

export type IMenuItemsActions = ActionType<typeof import('./actions')>

// index menuitems by restaurant id
export type IMenuItemsState = ImmutableObject<{
  [restaurantId: string]: {
    specialOffer: Array<IMenuItem>
  }
}>

const initState: IMenuItemsState = {}

const menuItemsReducer: Reducer<IMenuItemsState, IMenuItemsActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case LOAD_SPECIAL_OFFER_SUCCESS:
      return { ...action.payload }
    case SAVE_SPECIAL_OFFER:
      return {
        ...state,
        [action.payload.restaurantId]: {
          specialOffer: action.payload.menuItems,
        },
      }
    case CLEAN_UP_SPECIAL_OFFER:
      return action.payload.restaurantIds.reduce(
        (acc, id) => ({ ...acc, [id]: state[id] }),
        {}
      )
    default:
      return state
  }
}

export default menuItemsReducer
