import styled, { css } from 'styled-components'

import { H2 } from 'src/styles/typography'
import { media } from 'src/styles'

export const Wrap = styled.div`
  margin: 16px 0;
`

export const Title = styled(H2)`
  margin: 8px 0;
`

export type ListProps = {
  columns?: number
  smColumns?: number
  mdColumns?: number
}
export const List = styled.div<ListProps>`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);

  ${media.phone`
    grid-gap: 16px;
  `}

  ${({ smColumns }) =>
    smColumns &&
    css`
      ${media.phone`
        grid-template-columns: repeat(${smColumns}, 1fr);
      `}
    `}

  ${({ mdColumns }) =>
    mdColumns &&
    css`
      ${media.tablet`
        grid-template-columns: repeat(${mdColumns}, 1fr);
      `}
    `}
`
