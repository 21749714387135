import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Email from '@material-ui/icons/Email'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { HalfPageLayout } from 'src/components/PageLayout'
import routes from 'src/constants/routes'
import { handleEmailSignUp } from 'src/data/User/thunks'
import { useReduxDispatch } from 'src/data/store'

import bgImage from 'src/static/img/login_bg.jpg'

import TextField, { PasswordTextField } from 'src/components/TextField'
import Button, { ButtonLink } from 'src/components/Button'

import * as S from './styled'
import { mapErrorCodeToMessage } from 'src/utils/errorHandler'

interface FormValues {
  givenName: string
  familyName: string
  email: string
  password: string
}

const validationSchema = (t: TFunction) =>
  object<FormValues>({
    givenName: string().required(t('forms.errors.required')),
    familyName: string().required(t('forms.errors.required')),
    email: string().required(t('forms.errors.required')),
    password: string()
      .required(t('forms.errors.required'))
      .min(8, t('forms.errors.passwordShort')),
  })

const SignUpPage: React.FC = () => {
  const [t] = useTranslation()
  const dispatch = useReduxDispatch()

  const history = useHistory()

  const handleSubmit = async (
    values: FormValues,
    { setFieldValue, setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true)
    try {
      await dispatch(
        handleEmailSignUp({
          familyName: values.familyName,
          givenName: values.givenName,
          username: values.email,
          password: values.password,
        })
      )
      history.push(routes.index)
    } catch (error) {
      setStatus(
        error.networkError
          ? t('errors:offlineError')
          : mapErrorCodeToMessage(error.message)
      )

      // reset password
      setFieldValue('password', '', false)
      setSubmitting(false)
    }
  }

  return (
    <HalfPageLayout bgImage={bgImage} showProfileButton={false}>
      <S.Wrap>
        <S.InnerWrap>
          <S.H1>{t('signup.title')}</S.H1>
          <S.P>{t('signup.subtitle')}</S.P>
          <Formik<FormValues>
            onSubmit={handleSubmit}
            validationSchema={validationSchema(t)}
            initialValues={{
              givenName: '',
              familyName: '',
              email: '',
              password: '',
            }}
          >
            {({ isSubmitting, status, setStatus }) => (
              <Form onChange={() => setStatus('')}>
                {status && <S.ErrorMsg>{status}</S.ErrorMsg>}

                <TextField
                  name='givenName'
                  label={t('forms.signup.givenName')}
                  autoComplete='given-name'
                  endAdornment={<AccountCircle />}
                />

                <TextField
                  name='familyName'
                  label={t('forms.signup.familyName')}
                  autoComplete='family-name'
                  endAdornment={<AccountCircle />}
                />

                <TextField
                  name='email'
                  type='email'
                  label={t('forms.signup.email')}
                  endAdornment={<Email />}
                />

                <PasswordTextField
                  name='password'
                  label={t('forms.signup.password')}
                  autoComplete='new-password'
                />

                <S.ButtonsWrap>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    variant='primary'
                    size='medium'
                  >
                    {t('forms.signup.submit')}
                  </Button>

                  <ButtonLink variant='ghost' size='medium' to={routes.login}>
                    {t('signup.loginButton')}
                  </ButtonLink>
                </S.ButtonsWrap>
              </Form>
            )}
          </Formik>

          <S.TermsParagraph>{t('login.terms')}</S.TermsParagraph>
        </S.InnerWrap>
      </S.Wrap>
    </HalfPageLayout>
  )
}

export default SignUpPage
