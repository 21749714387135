import React, { useState, useEffect } from 'react'

import Face from '@material-ui/icons/Face'
import * as S from './styled'

type Props = {
  src: string
  size: number
}

const Avatar: React.FC<Props> = ({ src, size }) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [src])

  return src && !hasError ? (
    <S.Img
      src={src}
      alt='Avatar'
      onError={() => setHasError(true)}
      imgSize={size}
    />
  ) : (
    <Face style={{ fontSize: size }} />
  )
}

export default Avatar
