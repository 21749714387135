import styled, { css } from 'styled-components'
import { colors, radius } from 'src/styles'
import { Link } from 'react-router-dom'

interface Props {
  size: 'small' | 'medium' | 'large'
  variant: 'primary' | 'secondary' | 'ghost' | 'success' | 'error'
  fullWidth?: boolean
}

const buttonStyles = css<Props>`
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: center;

  cursor: pointer;
  border-radius: ${radius.secondary};
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  transition: all 0.5s ease;
  transition-property: background, background-color, color, transform;

  ${({ variant }) =>
    variant === 'primary'
      ? css`
          background-color: ${colors.alpha};
          color: ${colors.primary};
          text-transform: uppercase;
          &:hover {
            background-color: ${colors.alpha_dark};
            color: ${colors.primary};
          }
        `
      : variant === 'secondary'
      ? css`
          background: ${colors.secondary};
          color: ${colors.primary};
          text-transform: uppercase;
          &:hover {
            background: ${colors.secondary};
          }
        `
      : variant === 'ghost'
      ? css`
          background: none;
          border: 1px solid ${colors.gray_100};
          color: ${colors.gray_500};
          &:hover {
            background: ${colors.gray_100};
            color: ${colors.secondary};
          }
        `
      : variant === 'success'
      ? css`
          background-color: ${colors.success};
          color: ${colors.primary};
          text-transform: uppercase;
          &:hover {
            background-color: ${colors.success_dark};
          }
        `
      : variant === 'error'
      ? css`
          background-color: ${colors.error};
          color: ${colors.primary};
          text-transform: uppercase;
          &:hover {
            background-color: ${colors.error_dark};
          }
        `
      : ''};

  padding: ${({ size }) =>
    size === 'large' ? '18px 5px' : size === 'medium' ? '13px 5px' : '8px'};

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 1rem;
      line-height: 1rem;
    `}
`

export const ButtonLink = styled(Link)`
  ${buttonStyles};
`

export const Button = styled.button<Props & { hasLoader?: boolean }>`
  ${buttonStyles};

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${({ hasLoader }) =>
    hasLoader &&
    css`
      height: 47px;
      padding: 0;
    `}
`

type LoaderProps = Pick<Props, 'variant'>

export const LoaderWrap = styled.div<LoaderProps>`
  & > div {
    height: 20px !important;
    width: 20px !important;
  }

  svg {
    color: ${({ variant }) =>
      variant === 'primary'
        ? colors.primary
        : variant === 'secondary'
        ? colors.primary
        : variant === 'ghost'
        ? colors.secondary
        : colors.secondary};
  }
`
