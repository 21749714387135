import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectRestaurantsWithTableOrdering } from 'src/data/Restaurant/selectors'

import ListRestaurants from 'src/components/ListRestaurants'
import * as S from './styled'

const RestaurantList: React.FC = () => {
  const [t] = useTranslation()
  const restaurants = useSelector(selectRestaurantsWithTableOrdering)

  return (
    <S.Wrap>
      {restaurants.length === 0 && (
        <S.EmptyList variant='small'>{t('scanner.emptyList')}</S.EmptyList>
      )}
      <ListRestaurants
        columns={2}
        restaurants={restaurants}
        askTableNuber={true}
      />
    </S.Wrap>
  )
}

export default RestaurantList
