import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Email from '@material-ui/icons/Email'

import { HalfPageLayout } from 'src/components/PageLayout'
import routes from 'src/constants/routes'
import { handleSignIn } from 'src/data/User/thunks'
import { useReduxDispatch } from 'src/data/store'
import { mapErrorCodeToMessage } from 'src/utils/errorHandler'

import bgImage from 'src/static/img/login_bg.jpg'

import TextField, { PasswordTextField } from 'src/components/TextField'
import Button, { ButtonLink } from 'src/components/Button'

import * as S from './styled'

interface FormValues {
  email: string
  password: string
}

const validationSchema = (t: TFunction) =>
  object<FormValues>({
    email: string().required(t('forms.errors.required')),
    password: string().required(t('forms.errors.required')),
  })

const LoginPage: React.FC = () => {
  const [t] = useTranslation()
  const dispatch = useReduxDispatch()

  const history = useHistory()

  const handleSubmit = async (
    values: FormValues,
    { setFieldValue, setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true)
    try {
      await dispatch(
        handleSignIn({ username: values.email, password: values.password })
      )
      history.push(routes.index)
    } catch (error) {
      setStatus(
        error.networkError
          ? t('errors:offlineError')
          : mapErrorCodeToMessage(error.message)
      )

      // reset password
      setFieldValue('password', '', false)
      setSubmitting(false)
    }
  }

  return (
    <HalfPageLayout bgImage={bgImage} showProfileButton={false}>
      <S.Wrap>
        <S.InnerWrap>
          <S.H1>{t('login.title')}</S.H1>
          <Formik<FormValues>
            onSubmit={handleSubmit}
            validationSchema={validationSchema(t)}
            initialValues={{ email: '', password: '' }}
          >
            {({ isSubmitting, status, setStatus }) => (
              <Form onChange={() => setStatus('')}>
                {status && <S.ErrorMsg>{status}</S.ErrorMsg>}

                <TextField
                  name='email'
                  type='email'
                  label={t('forms.login.email')}
                  endAdornment={<Email />}
                  autoComplete='email'
                />

                <PasswordTextField
                  name='password'
                  label={t('forms.login.password')}
                  autoComplete='current-password'
                />

                {/* TODO: implement forget password page */}
                <S.ForgotPasswordWrap>
                  <S.ForgotPassword to={routes.index}>
                    {t('forms.login.forgotPassword')}
                  </S.ForgotPassword>
                </S.ForgotPasswordWrap>

                <S.ButtonsWrap>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    variant='primary'
                    size='medium'
                  >
                    {t('forms.login.submit')}
                  </Button>

                  <ButtonLink variant='ghost' size='medium' to={routes.signup}>
                    {t('login.registerButton')}
                  </ButtonLink>
                </S.ButtonsWrap>
              </Form>
            )}
          </Formik>

          <S.TermsParagraph>{t('login.terms')}</S.TermsParagraph>
        </S.InnerWrap>
      </S.Wrap>
    </HalfPageLayout>
  )
}

export default LoginPage
