import styled from 'styled-components'
import { colors } from 'src/styles'

export const Ul = styled.ul`
  margin-top: 8px;
`

export const Li = styled.li`
  display: flex;
  align-items: center;

  padding: 4px 0;

  & > * {
    padding-right: 8px;
  }
`

export const CheckWrap = styled.span`
  display: flex;
  color: ${colors.success};
`
