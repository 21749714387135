import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import routes from 'src/constants/routes'
import { H1, H2, P } from 'src/styles/typography'
import PageLayout from 'src/components/PageLayout'
import { useReduxDispatch } from 'src/data/store'
import { handleLoadCurrentUser, handleLogout } from 'src/data/User/thunks'
import { selectUser } from 'src/data/User/selectors'
import { ICreditCard } from 'src/data/User/gql/types'

import Button from 'src/components/Button'
import Avatar from 'src/components/Avatar'
import AddToHomeScreen from 'src/containers/AddToHomeScreen'

import PaymentMethods from './PaymentMethods'
import LanguageSwitch from './LanguageSwitch'
import NotificationsSettings from './NotificationsSettings'
import LocationSettings from './LocationSettings'

import * as S from './styled'

const ProfilePage: React.FC = () => {
  const dispatch = useReduxDispatch()
  const history = useHistory()
  const user = useSelector(selectUser)

  const [t] = useTranslation()

  useEffect(() => {
    dispatch(handleLoadCurrentUser())
  }, [dispatch])

  const handleLogoutClick = async () => {
    await dispatch(handleLogout())
    history.push(routes.index)
  }

  if (!user) {
    return null
  }

  return (
    <PageLayout>
      <H1>{t('profile.title')}</H1>
      <S.GridContainer container>
        <Grid item md={5} xs={12}>
          <S.AvatarWrap>
            <Avatar src={user.pictureUrl || ''} size={90} />
            <S.Name>{user.name}</S.Name>
            <P variant='small'>{user.email}</P>
            <Button
              size='small'
              variant='ghost'
              onClick={handleLogoutClick}
              fullWidth={false}
            >
              {t('profile.logout')}
            </Button>
          </S.AvatarWrap>
        </Grid>
        <Grid item md={1} xs={12}></Grid>
        <Grid item md={6} xs={12}>
          <S.SettingsWrap>
            <S.SettingsSingle>
              <H2>{t('profile.paymentMethods')}</H2>
              <PaymentMethods
                creditCards={user.creditCards as ICreditCard[]}
                defaultCreditCard={user.defaultCreditCard}
              />
            </S.SettingsSingle>

            <S.SettingsSingle>
              <H2>{t('profile.changeLanguage')}</H2>
              <LanguageSwitch />
            </S.SettingsSingle>

            <S.SettingsSingle>
              <H2>{t('profile.location.title')}</H2>
              <LocationSettings />
            </S.SettingsSingle>

            <S.SettingsSingle>
              <H2>{t('profile.notifications.title')}</H2>
              <NotificationsSettings />
            </S.SettingsSingle>
          </S.SettingsWrap>
        </Grid>
      </S.GridContainer>

      <S.AddToHomeScreenWrap>
        <AddToHomeScreen />
      </S.AddToHomeScreenWrap>
    </PageLayout>
  )
}

export default ProfilePage
