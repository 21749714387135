export enum LocalStorageKeys {
  USER_TOKEN = 'user_token',
  I18NEXT_LANG = 'i18next_lng',
  LAST_LOGIN_DATE = 'last_login_date',
  DEVICE_UID = 'device_uid',
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key: LocalStorageKeys) => {
  return localStorage.getItem(key) || ''
}
