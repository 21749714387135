import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

import { H1, H2 } from 'src/styles/typography'
import { colors } from 'src/styles'

export const Subtitle = styled(H2)`
  color: ${colors.gray_500};
`

export const Title = styled(H1)``

export const FiltersWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const FilterSwitch = styled(IconButton)`
  margin-bottom: 16px;
  background: ${colors.gray_100};

  &:focus {
    background: ${colors.gray_100};
  }
`
