import { IReduxState } from '../store'
import { OrderType } from 'src/__generated__/globalTypes'

// Restaurants
export const selectRestaurants = (state: IReduxState) =>
  state.restaurant.restaurants
    .map((a) => a)
    .sort((a, b) => {
      return a.config.isOpen === b.config.isOpen ? 0 : a.config.isOpen ? -1 : 1
    })

export const selectRestaurantsWithTableOrdering = (state: IReduxState) =>
  state.restaurant.restaurants.filter((res) =>
    res.config.orderTypes.find(
      (type) => type === OrderType.Delivery || type === OrderType.PickUp
    )
  )

export const selectRestaurantsByTag = (state: IReduxState, tagIg: string) =>
  !tagIg
    ? selectRestaurants(state)
    : selectRestaurants(state).filter((res) =>
        res.info.tags.find((tag) => tag.id === tagIg)
      )

// Local restaurant selection state
export const selectSelectedRestaurant = (state: IReduxState) =>
  state.restaurant.restaurants.find(
    (res) => res.id === state.restaurant.selectedId
  )

export const selectSelectedTableId = (state: IReduxState) =>
  state.restaurant.selectedTableId

export const selectSelectedDiscount = (state: IReduxState) =>
  state.restaurant.selectedDiscount

export const selectSelectedMenuItem = (state: IReduxState) => {
  const menuItemId = state.restaurant.selectedMenuItemId
  if (!menuItemId) {
    return null
  }
  return Object.values(state.menuItems)
    .flatMap((a) => a.specialOffer)
    .find((a) => a.id === menuItemId)
}

// Tags
export const selectTags = (state: IReduxState) => state.restaurant.tags

export const selectTagsNonEmpty = (state: IReduxState) => {
  const tagIds = state.restaurant.restaurants.reduce(
    (acc, res) => [...acc, ...res.info.tags.map((tag) => tag.id)],
    [] as string[]
  )
  return state.restaurant.tags.filter((tag) => tagIds.includes(tag.id))
}
