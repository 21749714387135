import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectRestaurants } from 'src/data/Restaurant/selectors'
import {
  handleGetRestaurants,
  handleGetRestaurantByParams,
  handleGetTags,
} from 'src/data/Restaurant/thunks'
import { parseQrCodeUrl } from 'src/utils/qrCodeUtils'
import { useReduxDispatch } from 'src/data/store'
import routes from 'src/constants/routes'
import { sortRestaurantsByDistance } from 'src/utils/locationUtils'
import { selectCurrentLocation } from 'src/data/App/selectors'
import { selectAllSpecialOffers } from 'src/data/MenuItems/selectors'
import { handleGetAllSpecialsOffers } from 'src/data/MenuItems/thunks'

import PageLayout from 'src/components/PageLayout'
import AddToHomeScreen from 'src/containers/AddToHomeScreen'
import ListRestaurants from 'src/components/ListRestaurants'
import ListRestaurantsCarousel from 'src/components/ListRestaurantsCarousel'
import ListMealsCarousel from 'src/components/ListMealsCarousel'
import Greeting from './Greeting'
import ActionButtons from './ActionButtons'
import BottomBar from './BottomBar'
import LocationHandler from './LocationHandler'

import * as S from './styled'

const MainPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useReduxDispatch()

  // select data from store
  const restaurants = useSelector(selectRestaurants)
  const currentLocation = useSelector(selectCurrentLocation)
  const specialOffers = useSelector(selectAllSpecialOffers)

  useEffect(() => {
    const loadData = async () => {
      // load all
      await dispatch(handleGetRestaurants())
      await dispatch(handleGetAllSpecialsOffers())
      await dispatch(handleGetTags())

      // handle url entered by custom scan
      const params = parseQrCodeUrl(window.location.href)
      const restaurant = await dispatch(handleGetRestaurantByParams(params))
      if (restaurant) {
        history.push(
          routes.restaurantDetailCompose(
            restaurant.config.customer.urlFriendlyName
          )
        )
      }
    }
    loadData()
  }, [dispatch, history])

  // prepare restaurants arrays
  const restaurantsNearby = useMemo(() => {
    return currentLocation
      ? sortRestaurantsByDistance(restaurants, currentLocation.coords)
      : []
  }, [restaurants, currentLocation])

  const restaurantSelected = (() => {
    // get the closest restaurant
    if (restaurantsNearby.length > 0) {
      return restaurantsNearby.slice(0, 1)
    }
    // or get a pseudo random place in all restaurants, which is open
    const filtered = restaurants.filter((a) => a.config.isOpen)
    return [
      filtered[Math.floor((new Date().getSeconds() / 60) * filtered.length)],
    ]
  })()

  return (
    <PageLayout showBackButton={false}>
      <S.HeaderWrap>
        <Grid container>
          <Grid
            item
            xs={12}
            md={5}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Greeting />
          </Grid>
          <Grid item md={2} lg={3} />
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ActionButtons />
          </Grid>
        </Grid>
      </S.HeaderWrap>

      <LocationHandler />

      {restaurantsNearby.length > 0 && (
        <S.SectionWrap>
          <ListRestaurantsCarousel
            title={t('main.nearby')}
            restaurants={restaurantsNearby}
            askTableNuber={false}
          />
        </S.SectionWrap>
      )}

      <S.SectionWrap>
        <ListRestaurants
          title={t('main.selected')}
          columns={1}
          restaurants={restaurantSelected}
          askTableNuber={false}
          size={'large'}
        />
      </S.SectionWrap>

      <S.SectionWrap>
        <ListMealsCarousel
          title={t('main.mealsAround')}
          menuItems={specialOffers}
          restaurants={restaurants}
        />
      </S.SectionWrap>

      <AddToHomeScreen />

      <S.SectionWrap>
        <ListRestaurants
          title={t('main.allPlaces')}
          columns={1}
          smColumns={2}
          mdColumns={3}
          restaurants={restaurants}
          askTableNuber={false}
        />
      </S.SectionWrap>

      <BottomBar />
    </PageLayout>
  )
}

export default MainPage
