import React from 'react'
import styled from 'styled-components'
import { P } from 'src/styles/typography'
import { colors, radius, media } from 'src/styles'
import { Grid } from '@material-ui/core'

export const GridContainer = styled((props) => <Grid {...props} container />)`
  padding: 32px 0;

  ${media.tablet`
    margin-top: 64px;
    background: ${colors.gray_050};
    border-radius: ${radius.primary};
    min-height: 60vh;
  `}
`

export const AvatarWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin: 16px 0 32px 0;

    ${media.phone`
      margin: 32px 0 0 0;
    `}
  }
`

export const Name = styled(P)`
  margin: 16px 0 8px;

  ${media.tablet`
    margin: 64px 0 8px;
  `}
`

export const SettingsWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SettingsSingle = styled.div`
  margin: 16px 0;
`

export const AddToHomeScreenWrap = styled.div`
  margin: 32px 0;
`
