import { action } from 'typesafe-actions'
import {
  LOAD_RESTAURANTS_REQUEST,
  LOAD_RESTAURANTS_SUCCESS,
  LOAD_RESTAURANTS_FAILURE,
  SAVE_SELECTED_RESTAURANT_ID,
  SAVE_SELECTED_TABLE_ID,
  SAVE_SELECTED_DISCOUNT,
  SAVE_SELECTED_MENU_ITEM_ID,
  LOAD_TAGS_SUCCESS,
} from './constants'
import { IRestaurant, IDiscount, ITag } from './gql/types'

export const loadRestaurantsRequest = () => action(LOAD_RESTAURANTS_REQUEST)

export const loadRestaurantsSuccess = (restaurants: IRestaurant[]) =>
  action(LOAD_RESTAURANTS_SUCCESS, restaurants)

export const loadRestaurantsFailure = () => action(LOAD_RESTAURANTS_FAILURE)

export const saveSelectedRestaurantId = (selectedId: string | undefined) =>
  action(SAVE_SELECTED_RESTAURANT_ID, selectedId)

export const saveSelectedTableId = (tableId: string | undefined) =>
  action(SAVE_SELECTED_TABLE_ID, tableId)

export const saveSelectedDiscount = (discount: IDiscount | undefined) =>
  action(SAVE_SELECTED_DISCOUNT, discount)

export const saveSelectedMenuItemId = (menuItemId: string | undefined) =>
  action(SAVE_SELECTED_MENU_ITEM_ID, menuItemId)

export const loadTagsSuccess = (tags: ITag[]) => action(LOAD_TAGS_SUCCESS, tags)
