import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'
import { IAppLocation } from './gql/types'
import { SAVE_CURRENT_LOCATION } from './constants'
import { isCoreDataTimestamp } from 'src/utils/timeUtils'

export type IAppActions = ActionType<typeof import('./actions')>

export type IAppState = ImmutableObject<{
  currentLocation?: IAppLocation
}>

const initState: IAppState = {
  currentLocation: undefined,
}

const appReducer: Reducer<IAppState, IAppActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case SAVE_CURRENT_LOCATION:
      // the timestamp can be different on safari
      const timestamp = action.payload.timestamp
        ? isCoreDataTimestamp(action.payload.timestamp)
          ? action.payload.timestamp + 978307200000
          : action.payload.timestamp
        : Date.now()
      return {
        ...state,
        currentLocation: {
          coords: action.payload.coords,
          timestamp: timestamp,
          provider: action.payload.provider,
        },
      }
    default:
      return state
  }
}

export default appReducer
