import styled from 'styled-components'
import { media } from 'src/styles'

export const EnableButtonWrap = styled.div`
  margin: 16px 0;

  ${media.phone`
    margin: 0;
    max-width: 450px;
  `}
`
