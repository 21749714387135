import styled from 'styled-components'
import { P } from 'src/styles/typography'

export const Wrap = styled.div`
  min-height: 30vh;
`

export const EmptyList = styled(P)``

export const SectionTitle = styled(P)`
  margin: 64px 0 16px;
`
