import styled from 'styled-components'

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-right: 8px;
`

export const FormWrap = styled.div`
  padding-bottom: 16px;
`
