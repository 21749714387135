import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import {
  selectRestaurantsByTag,
  selectRestaurants,
} from 'src/data/Restaurant/selectors'
import { useReduxDispatch, useReduxSelector } from 'src/data/store'
import { handleGetRestaurants, handleGetTags } from 'src/data/Restaurant/thunks'
import { selectCurrentLocation } from 'src/data/App/selectors'

import PageLayout from 'src/components/PageLayout'
import TagsFilter from './TagsFilter'
import Search from './Search'
import ListRestaurants from 'src/components/ListRestaurants'
import { sortRestaurantsByDistance } from 'src/utils/locationUtils'

import * as S from './styled'

const RestaurantListPage: React.FC = () => {
  const [filterType, setFilterType] = useState<'tags' | 'search'>('tags')
  const [selectedTagId, setSelectedTagId] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [t] = useTranslation()

  const dispatch = useReduxDispatch()
  const currentLocation = useReduxSelector(selectCurrentLocation)
  const restaurants = useReduxSelector((state) =>
    selectRestaurantsByTag(state, selectedTagId)
  )
  const allRestaurants = useReduxSelector(selectRestaurants).filter((res) =>
    res.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  useEffect(() => {
    dispatch(handleGetRestaurants())
    dispatch(handleGetTags())
  }, [dispatch])

  const toggleFilterType = useCallback(() => {
    setFilterType((state) => {
      setSelectedTagId('')
      setSearchQuery('')
      return state === 'tags' ? 'search' : 'tags'
    })
  }, [setFilterType])

  const restaurantsSorted = useMemo(() => {
    const array = filterType === 'search' ? allRestaurants : restaurants
    return currentLocation
      ? sortRestaurantsByDistance(array, currentLocation.coords)
      : array
  }, [allRestaurants, restaurants, currentLocation, filterType])

  return (
    <PageLayout>
      <S.Subtitle>{t('explore.subtitle')}</S.Subtitle>
      <S.Title>{t('explore.title')}</S.Title>
      <S.FiltersWrap>
        <Search
          isOpen={filterType === 'search'}
          value={searchQuery}
          onChange={(value) => setSearchQuery(value)}
        />
        <TagsFilter
          isOpen={filterType === 'tags'}
          selectedId={selectedTagId}
          onSelected={(tagId) => setSelectedTagId(tagId)}
        />
        <S.FilterSwitch onClick={toggleFilterType}>
          {filterType === 'search' ? <Close /> : <SearchIcon />}
        </S.FilterSwitch>
      </S.FiltersWrap>

      <ListRestaurants
        columns={1}
        smColumns={2}
        mdColumns={3}
        restaurants={restaurantsSorted}
        askTableNuber={false}
      />
    </PageLayout>
  )
}

export default RestaurantListPage
