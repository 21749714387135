import {
  IRestaurant,
  IRestaurantWithDistance,
} from 'src/data/Restaurant/gql/types'

interface IPlace {
  name: string
  lng: number
  lat: number
  radius: number
  showInMap: boolean
}
export const PINGL_AREAS: IPlace[] = [
  {
    name: 'Cafedu, Praha',
    lat: 50.078703,
    lng: 14.43258,
    radius: 500,
    showInMap: false,
  },
  {
    name: 'Nám. W. Churchilla, Praha',
    lat: 50.084761,
    lng: 14.44133,
    radius: 500,
    showInMap: false,
  },
  {
    name: 'Praha',
    lat: 50.082717,
    lng: 14.439345,
    radius: 30000,
    showInMap: true,
  },
  {
    name: 'Brno',
    lat: 49.194858,
    lng: 16.60921,
    radius: 30000,
    showInMap: true,
  },
  {
    name: 'Olomouc',
    lat: 49.594044,
    lng: 17.251404,
    radius: 30000,
    showInMap: false,
  },
  {
    name: 'Karviná',
    lat: 49.855349,
    lng: 18.544246,
    radius: 30000,
    showInMap: true,
  },
]

// credit to https://stackoverflow.com/a/11172685
// generally used geo measurement function
export const measureDistance = (
  placeA: {
    lat: number
    lng: number
  },
  placeB: {
    lat: number
    lng: number
  }
) => {
  const { lat: lat1, lng: lng1 } = placeA
  const { lat: lat2, lng: lng2 } = placeB

  const R = 6378.137 // Radius of earth in KM
  const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
  const dLng = (lng2 * Math.PI) / 180 - (lng1 * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d * 1000 // meters
}

/**
 * Sort restaurants by:
 * 1 - is open
 * 2 - by distance
 * @param restaurants To be sorted
 * @param coords Location sorting to be relative to
 */
export const sortRestaurantsByDistance = (
  restaurants: ImmutableArray<IRestaurant>,
  coords: {
    latitude: number
    longitude: number
  },
  config?: {
    ignoreOpenState?: boolean
  }
): IRestaurantWithDistance[] =>
  restaurants
    .map((res) => {
      const distance = measureDistance(
        {
          lat: coords.latitude,
          lng: coords.longitude,
        },
        {
          lat: res.info.placeLocation.coordinates[0],
          lng: res.info.placeLocation.coordinates[1],
        }
      )
      return {
        ...res,
        distance: isNaN(distance) ? undefined : distance,
      }
    })
    .sort((a, b) => {
      const sortByOpen = config?.ignoreOpenState
        ? 0
        : a.config.isOpen === b.config.isOpen
        ? 0
        : a.config.isOpen
        ? -1
        : 1
      const sortByActive = config?.ignoreOpenState
        ? 0
        : a.config.isActive === b.config.isActive
        ? 0
        : a.config.isActive
        ? -1
        : 1
      const sortByDistance =
        a.distance === undefined && b.distance === undefined
          ? 0
          : a.distance === undefined
          ? -1
          : b.distance === undefined
          ? -1
          : a.distance - b.distance
      return sortByOpen || sortByActive || sortByDistance
    })
