import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from 'src/styles'

type Props = {
  inverted: boolean
}

export const Header = styled.header<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 45px;

  & > * {
    flex: 1;
    display: flex;

    &:nth-child(1) {
      justify-content: flex-start;
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }
  }

  ${({ inverted }) =>
    inverted &&
    css`
      button,
      a {
        color: ${colors.primary};
      }
    `}
`

export const BackLink = styled(Link)``
