import { gqlApi } from '../gqlApi'
import { SEND_TEST_FCM_NOTIFICATION } from './gql/queries'
import { sendTestFCMNotification } from './gql/__generated__/sendTestFCMNotification'

/* QUERIES */
export const requestSendTestFcmNotification = async () => {
  return (
    await gqlApi.apiQueryRequest<sendTestFCMNotification>(
      SEND_TEST_FCM_NOTIFICATION
    )
  ).sendTestFCMNotification
}
