import React from 'react'
import Store from '@material-ui/icons/Store'
import Explore from '@material-ui/icons/Explore'
import Fullscreen from '@material-ui/icons/Fullscreen'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import { useTranslation } from 'react-i18next'

import routes from 'src/constants/routes'

import * as S from './styled'

const ActionButtons: React.FC = () => {
  const [t] = useTranslation()

  return (
    <S.Wrap>
      <S.Link to={routes.scanner}>
        <S.IconWrap>
          <Fullscreen fontSize='large' />
        </S.IconWrap>
        {t('main.navigation.scan')}
      </S.Link>

      <S.Link to={routes.explore}>
        <S.IconWrap>
          <Store fontSize='large' />
        </S.IconWrap>
        {t('main.navigation.explore')}
      </S.Link>

      <S.Link to={routes.map}>
        <S.IconWrap>
          <Explore fontSize='large' />
        </S.IconWrap>
        {t('main.navigation.map')}
      </S.Link>

      <S.Link to={routes.orderHistory}>
        <S.IconWrap>
          <ShoppingBasket fontSize='large' />
        </S.IconWrap>
        {t('main.navigation.orderHistory')}
      </S.Link>
    </S.Wrap>
  )
}

export default ActionButtons
