import styled from 'styled-components'

import { H2 } from 'src/styles/typography'
import { media, colors } from 'src/styles'

export const Wrap = styled.div`
  margin-bottom: 25px;
`

export const Title = styled(H2)`
  margin: 8px 0;
`

export const SliderWrap = styled.div`
  margin: -4px;

  ${media.phone`
    margin: -8px;
  `}

  .slick-dots {
    opacity: 0.5;
  }

  .slick-dots li button::before {
    font-size: 10px;
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-next {
    right: -45px;
  }

  .slick-prev {
    left: -45px;
  }

  .slick-prev.slick-disabled::before,
  .slick-next.slick-disabled::before {
    cursor: default;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
    color: ${colors.gray_500};
  }
`

export const Slide = styled.div`
  padding: 4px;

  ${media.phone`
    padding: 8px;
  `}
`
