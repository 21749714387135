import React, { useEffect } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import routes, { isRoutePrivate } from 'src/constants/routes'
import { getLocalStorage, LocalStorageKeys } from 'src/utils/localStorage'
import { useReduxDispatch } from 'src/data/store'
import { handleVerifyUser } from 'src/data/User/thunks'
import { useSelector } from 'react-redux'
import { selectUser } from 'src/data/User/selectors'

interface IProps {
  component: React.ComponentType<any>
}

type Props = IProps &
  RouteProps & {
    path?: string // force path to be string
    title?: string // option to change page title
  }

/**
 * Renders a route, that will check its token
 */
const AppRoute: React.FC<Props> = ({
  component: Component,
  title,
  path,
  ...rest
}) => {
  useEffect(() => {
    document.title = `Pingl${title ? ` ${title}` : ''}`
  }, [title])

  const dispatch = useReduxDispatch()
  const user = useSelector(selectUser)

  const isPrivate = !path ? false : isRoutePrivate(path)
  const isAuthenticated = user || getLocalStorage(LocalStorageKeys.USER_TOKEN)

  useEffect(() => {
    if (isAuthenticated) {
      const verifyUser = () => dispatch(handleVerifyUser())

      if (navigator.onLine) {
        verifyUser()
      }
      // verify the user when he becomes online
      window.addEventListener('online', verifyUser)
      return () => window.removeEventListener('online', verifyUser)
    }
  }, [path, dispatch, isAuthenticated])

  return isPrivate && !isAuthenticated ? (
    <Redirect to={routes.login} />
  ) : (
    <Route path={path} {...rest} component={Component} />
  )
}

export default AppRoute
