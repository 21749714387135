import styled from 'styled-components'
import { colors, media } from 'src/styles'

export const Ul = styled.ul`
  margin-top: 8px;
`

export const Li = styled.li`
  display: flex;
  align-items: center;

  padding: 4px 0;

  & > * {
    padding-right: 8px;
  }
`

type IconProps = {
  isSuccess?: boolean
}

export const IconWrap = styled.span<IconProps>`
  display: flex;
  color: ${({ isSuccess }) => (isSuccess ? colors.success : colors.error)};
`

export const EnableButtonWrap = styled.div`
  margin-top: 8px;
  max-width: 450px;
  ${media.phone`
    margin-right: 8px;
  `}
`
export const Placeholder = styled.div`
  height: 70px;
`
