import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

import { P } from 'src/styles/typography'
import Header from '../Header'
import * as S from './styled'

type Props = {
  showBackButton?: boolean
  showProfileButton?: boolean
}

const PageLayout: React.FC<Props> = ({
  children,
  showBackButton = true,
  showProfileButton = true,
}) => {
  return (
    <Container maxWidth='lg' style={{ padding: 0 }}>
      <S.LayoutInnerWrap>
        <Header
          showBackButton={showBackButton}
          showProfileButton={showProfileButton}
        />
        {children}
      </S.LayoutInnerWrap>
    </Container>
  )
}

export const HalfPageLayout: React.FC<Props & { bgImage: string }> = ({
  children,
  showBackButton = true,
  showProfileButton = true,
  bgImage,
}) => {
  return (
    <Grid container>
      <S.GridWithImage
        item
        xs={false}
        sm={4}
        md={7}
        style={{ backgroundImage: `url(${bgImage})` }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <S.LayoutInnerWrap>
          <Header
            showBackButton={showBackButton}
            showProfileButton={showProfileButton}
          />
          {children}
        </S.LayoutInnerWrap>
      </Grid>
    </Grid>
  )
}

export const TwoColumnPageLayout: React.FC<
  Props & {
    drawerContent: React.ReactNode
    drawerTitle: string
    showMobileHeader?: boolean
    invertedHeader?: boolean
  }
> = ({
  children,
  showMobileHeader = false,
  showBackButton = true,
  showProfileButton = true,
  drawerContent: SecondaryChildren,
  drawerTitle,
  invertedHeader,
}) => {
  const handleHeaderClick = () => {
    if (window.scrollY < 200) {
      window.scrollTo({
        top: window.scrollY + 400,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Grid container>
      <S.GridFixedColumn item xs={12} sm={6} md={7}>
        {showMobileHeader && (
          <S.MobileOnlyWrap>
            <Header
              showBackButton={showBackButton}
              showProfileButton={showProfileButton}
              inverted={invertedHeader}
            />
          </S.MobileOnlyWrap>
        )}
        {children}
      </S.GridFixedColumn>
      <S.GridBottomColumn
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        <S.TabletOnlyWrap>
          <Header
            showBackButton={showBackButton}
            showProfileButton={showProfileButton}
          />
        </S.TabletOnlyWrap>
        <S.BottomHeader onClick={handleHeaderClick}>
          <P>{drawerTitle}</P>
          <KeyboardBackspace />
        </S.BottomHeader>
        {SecondaryChildren}
      </S.GridBottomColumn>
    </Grid>
  )
}

export default PageLayout
