import { useEffect, useRef } from 'react'

// Credit to: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void,
  delay: number,
  dependencyArray?: any[]
) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback, dependencyArray])

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
