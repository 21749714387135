import { IOpenTimes } from 'src/data/Restaurant/gql/types'

type PartOfTheDay =
  | 'breakfast'
  | 'brunch'
  | 'lunch'
  | 'coffee'
  | 'dinner'
  | 'night'

export const getPartOfTheDay = (): PartOfTheDay => {
  const hours = new Date().getHours()
  switch (true) {
    case hours < 4:
      return 'night'
    case hours < 9:
      return 'breakfast'
    case hours < 10:
      return 'brunch'
    case hours < 14:
      return 'lunch'
    case hours < 17:
      return 'coffee'
    case hours < 22:
      return 'dinner'
    default:
      return 'night'
  }
}

export const openTimeToDate = (vars: {
  hour: number
  minute: number
  addDays?: number
}) => {
  const date = new Date()
  date.setHours(vars.hour, vars.minute, 0, 0)
  if (vars.addDays !== undefined) {
    date.setDate(date.getDate() + vars.addDays)
  }
  return date
}

export const getRestaurantClosingDate = (openTimes: IOpenTimes) => {
  const now = new Date()

  // get right days from the
  const yesterdayOpenTimes = openTimes[(now.getDay() - 2 + 7) % 7].time
  const todayOpenTimes = openTimes[(now.getDay() - 1 + 7) % 7].time
  const tomorrowOpenTimes = openTimes[(now.getDay() - 0 + 7) % 7].time

  const yesterday = {
    open: openTimeToDate({ ...yesterdayOpenTimes.open, addDays: -1 }),
    close: openTimeToDate({ ...yesterdayOpenTimes.close, addDays: -1 }),
  }
  const today = {
    open: openTimeToDate({ ...todayOpenTimes.open }),
    close: openTimeToDate({ ...todayOpenTimes.close }),
  }
  const tomorrow = {
    open: openTimeToDate({ ...tomorrowOpenTimes.open, addDays: 1 }),
    close: openTimeToDate({ ...tomorrowOpenTimes.close, addDays: 1 }),
  }

  // modify in case of overmidnight opening
  if (yesterday.close.getTime() < yesterday.open.getTime()) {
    yesterday.close.setDate(yesterday.close.getDate() + 1)
  }
  if (today.close.getTime() < today.open.getTime()) {
    today.close.setDate(today.close.getDate() + 1)
  }
  if (tomorrow.close.getTime() < tomorrow.open.getTime()) {
    tomorrow.close.setDate(tomorrow.close.getDate() + 1)
  }

  // choose the right closing time
  let closingDate: Date
  if (now.getTime() < yesterday.close.getTime()) {
    closingDate = yesterday.close
  } else if (
    today.close.getHours() === 23 &&
    today.close.getMinutes() === 59 &&
    tomorrow.open.getHours() === 0 &&
    tomorrow.open.getMinutes() === 0
  ) {
    closingDate = tomorrow.close
  } else {
    closingDate = today.close
  }
  return closingDate
}

// 978307200000 equals ms between 1/1/1970 and 1/1/2001
// epxct that the timestamt should be around today
export const isCoreDataTimestamp = (timestamp: number) =>
  Math.abs(Date.now() - timestamp - 978307200000) < 978307200000 * 0.0001
