import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Schedule from '@material-ui/icons/Schedule'

import { IRestaurant, IOpenTimes } from 'src/data/Restaurant/gql/types'

import * as S from './styled'
import { getRestaurantClosingDate } from 'src/utils/timeUtils'
import { useInterval } from 'src/utils/useInterval'

type Props = {
  restaurant: IRestaurant
} & S.StyleProps

const InactiveBar: React.FC<Props> = ({ restaurant, variant }) => {
  const [t] = useTranslation()
  const [text, setText] = useState('')

  const getClosingTextCallback = useCallback(
    (openTimes: IOpenTimes): string => {
      const closingDate = getRestaurantClosingDate(openTimes)

      // in how many minutes will the place close
      const millisDiff = closingDate.getTime() - Date.now()

      // restaurant is already closed, hide the banner
      if (millisDiff < 0) {
        return t('restaurant.closed')
      }

      // schedule the banner to show now or X mins before
      const minsBefore = 30
      const minsDiff = Math.round(millisDiff / 60 / 1000)

      if (minsDiff < minsBefore) {
        return t('restaurant.closing', { minutes: minsDiff.toString() })
      } else {
        return ''
      }
    },
    [t]
  )

  const handleInactiveState = useCallback(() => {
    const { isActive, isOpen, openTimes } = restaurant.config
    if (!isActive) {
      setText(t('restaurant.inactive'))
      return
    }
    if (!isOpen) {
      setText(t('restaurant.closed'))
      return
    }
    const text = getClosingTextCallback(openTimes)
    setText(text)
  }, [restaurant.config, t, getClosingTextCallback])

  useEffect(() => {
    handleInactiveState()
  }, [handleInactiveState])

  useInterval(
    () => {
      handleInactiveState()
    },
    60 * 1000,
    [handleInactiveState]
  )

  return text ? (
    <S.Wrap variant={variant}>
      <Schedule />
      <span>{text}</span>
    </S.Wrap>
  ) : null
}

export default InactiveBar
