import React, { useCallback, useEffect, useRef } from 'react'
import MaterialTextField from '@material-ui/core/TextField'

import * as S from './styled'

type Props = {
  isOpen: boolean
  value: string
  onChange: (value: string) => void
}

const Search: React.FC<Props> = ({ isOpen, value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>()

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()
    }
  }, [isOpen])

  return (
    <S.Wrap isOpen={isOpen}>
      <MaterialTextField
        name={'search'}
        onChange={(e) => handleChange(e)}
        variant='outlined'
        margin='normal'
        fullWidth
        autoFocus={true}
        label='Search by name'
        value={value}
        inputRef={inputRef}
        InputProps={{
          style: { fontSize: '16px' },
        }}
      />
    </S.Wrap>
  )
}

export default Search
