import React from 'react'
import PageLayout from 'src/components/PageLayout'

import { P } from 'src/styles/typography'

const OrderHistoryPage: React.FC = () => {
  return (
    <PageLayout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          minHeight: '80vh',
        }}
      >
        <P>not implemented</P>
      </div>
    </PageLayout>
  )
}

export default OrderHistoryPage
