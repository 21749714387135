import React, { useState } from 'react'
import { useField } from 'formik'
import MaterialTextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import * as S from './styled'

type Props = {
  name: string
  label: string
  type?: 'text' | 'email' | 'password'
  endAdornment?: React.ReactNode
  required?: boolean
  autoFocus?: boolean
  autoComplete?: string
}

// Wrapper mapping Formik API to Material UI API
const TextField: React.FC<Props> = ({
  name,
  label,
  type,
  endAdornment,
  required,
  autoFocus,
  autoComplete,
}) => {
  const [field, meta] = useField(name)

  return (
    <MaterialTextField
      type={type}
      name={field.name}
      id={field.name}
      error={!!(meta.error && meta.touched)}
      onChange={field.onChange}
      onBlur={field.onBlur}
      helperText={meta.error && meta.touched && String(meta.error)}
      variant='outlined'
      margin='normal'
      fullWidth
      required={required}
      autoComplete={autoComplete || (type === 'email' ? 'email' : undefined)}
      autoFocus={autoFocus}
      label={label}
      value={field.value}
      InputProps={{
        style: { fontSize: '16px' },
        endAdornment: endAdornment ? (
          <S.InputAdornment position='end'>{endAdornment}</S.InputAdornment>
        ) : undefined,
      }}
    />
  )
}

export const PasswordTextField: React.FC<Omit<
  Props,
  'type' | 'afterContent'
>> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton
          aria-label='toggle password visibility'
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
    />
  )
}

export default TextField
