import React from 'react'
import ReactDOM from 'react-dom'

// import i18Hn to be bundled
import './i18n'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './data/store'

import App from './components/App'
import * as serviceWorker from './serviceWorker'

import GlobalStyles from './styles/globalStyles'

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <GlobalStyles />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )

if (module.hot) {
  module.hot.accept('./components/App', render)
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
