import { TAction } from '../store'

import * as menuItemsApi from './api'
import { OrderType } from 'src/__generated__/globalTypes'
import { selectRestaurants } from '../Restaurant/selectors'
import { loadSpecialOfferSuccess } from './actions'

export const handleGetAllSpecialsOffers: TAction<boolean> = () => async (
  dispatch,
  getState
) => {
  try {
    const state = getState()
    const restaurants = selectRestaurants(state)
    // load special offer
    const promises = restaurants.map(async (res) => {
      const restaurantId = res.id
      const data = await menuItemsApi.requestGetSpecialOfferMenuItems({
        orderType: OrderType.TakeAway,
        restaurantId,
      })
      return { [restaurantId]: { specialOffer: data.items } }
    })
    // reduce to object
    const result = (await Promise.all(promises)).reduce(
      (acc, single) => ({ ...acc, ...single }),
      {}
    )
    // save
    dispatch(loadSpecialOfferSuccess(result))
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
