import styled, { css } from 'styled-components'
import { colors, radius, animations } from 'src/styles'

type Props = {
  variant: 'normal' | 'success' | 'error'
}

export const Wrap = styled.div<Props>`
  display: flex;
  align-items: center;

  padding: 10px 16px;

  border-radius: ${radius.secondary};
  ${({ variant }) =>
    variant === 'normal'
      ? css`
          color: ${colors.secondary};
          background: ${colors.gray_100};
        `
      : variant === 'success'
      ? css`
          color: ${colors.success_dark};
          background: ${colors.success_light};
        `
      : variant === 'error'
      ? css`
          color: ${colors.error};
          background: ${colors.error_light};
        `
      : ''}

  cursor: pointer;

  ${animations.scaleOnHover()}

  span {
    margin: 0 10px;
  }
`

export const LeftWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const LoaderWrap = styled.div`
  height: 30px;
  padding-top: 5px;
`
