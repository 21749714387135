import {
  GET_RESTAURANTS,
  GET_RESTAURANT_BY_TABLE_ID,
  GET_RESTAURANT_BY_URL_FRIENDLY_NAME,
  GET_DISCOUNT_BY_CODE,
  GET_ALL_TAGS,
} from './gql/queries'
import { gqlApi } from '../gqlApi'
import {
  getRestaurants,
  getRestaurantsVariables,
} from './gql/__generated__/getRestaurants'
import {
  getRestaurantByTableId,
  getRestaurantByTableIdVariables,
} from './gql/__generated__/getRestaurantByTableId'
import {
  getRestaurantByUrlFriendlyName,
  getRestaurantByUrlFriendlyNameVariables,
} from './gql/__generated__/getRestaurantByUrlFriendlyName'
import {
  getDiscountByCode,
  getDiscountByCodeVariables,
} from './gql/__generated__/getDiscountByCode'
import { getAllTags } from './gql/__generated__/getAllTags'
import { ClientOfflineMode } from 'src/client'

/* QUERIES */
export const requestGetRestaurants = async (
  variables: getRestaurantsVariables
) => {
  return (
    await gqlApi.apiQueryRequest<getRestaurants, getRestaurantsVariables>(
      GET_RESTAURANTS,
      variables,
      { mode: ClientOfflineMode.WAIT_FOR_ONLINE }
    )
  ).getRestaurants
}

export const requestGetRestaurantByTableId = async (
  variables: getRestaurantByTableIdVariables
) => {
  return (
    await gqlApi.apiQueryRequest<
      getRestaurantByTableId,
      getRestaurantByTableIdVariables
    >(GET_RESTAURANT_BY_TABLE_ID, variables)
  ).getRestaurantByTableId
}

export const requestGetRestaurantBySlug = async (
  variables: getRestaurantByUrlFriendlyNameVariables
) => {
  return (
    await gqlApi.apiQueryRequest<
      getRestaurantByUrlFriendlyName,
      getRestaurantByUrlFriendlyNameVariables
    >(GET_RESTAURANT_BY_URL_FRIENDLY_NAME, variables)
  ).getRestaurantByUrlFriendlyName
}

export const requestGetDiscountByCode = async (
  variables: getDiscountByCodeVariables
) => {
  return (
    await gqlApi.apiQueryRequest<getDiscountByCode, getDiscountByCodeVariables>(
      GET_DISCOUNT_BY_CODE,
      variables
    )
  ).getDiscountByCode
}

export const requestGetAllTags = async () => {
  return (
    await gqlApi.apiQueryRequest<getAllTags>(GET_ALL_TAGS, undefined, {
      mode: ClientOfflineMode.WAIT_FOR_ONLINE,
    })
  ).getAllTags
}
