import styled, { css } from 'styled-components'
import { colors } from 'src/styles'

export const fontFamily = {
  primary: 'Poppins',
}

export const fontWeight = {
  medium: '500',
  semiBold: '600',
  bold: '700',
}

type PProps = {
  variant?: 'small' | 'normal'
}
export const P = styled.p<PProps>`
  line-height: 1.6rem;

  ${({ variant }) =>
    variant === 'small'
      ? css`
          color: ${colors.gray_500};
          font-size: 0.9rem;
        `
      : css`
          color: ${colors.secondary};
          font-size: 1.2rem;
        `}
`

// Headlines
const baseHeadlineStyle = css`
  line-height: normal;
  font-weight: ${fontWeight.bold};
  color: ${colors.secondary};
`

export const H1 = styled.h1`
  font-size: 2.6rem;
  ${baseHeadlineStyle}
`

export const H2 = styled.h2`
  font-size: 1.7rem;
  ${baseHeadlineStyle}
`

export const H3 = styled.h2`
  font-size: 1.5rem;
  ${baseHeadlineStyle}
`
