import React from 'react'

import {
  IRestaurant,
  IRestaurantWithDistance,
} from 'src/data/Restaurant/gql/types'
import RestaurantThumbnail from 'src/containers/RestaurantThumbnail'
import Carousel from '../Carousel'

type Props = {
  title?: string
  restaurants: ImmutableArray<IRestaurant | IRestaurantWithDistance>
  askTableNuber: boolean
}

const ListRestaurantsCarousel: React.FC<Props> = ({
  title,
  restaurants,
  askTableNuber,
}) => {
  const slidesArray = restaurants.map((restaurant) => ({
    element: (
      <RestaurantThumbnail data={restaurant} askTableNumber={askTableNuber} />
    ),
    key: restaurant.id,
  }))

  return (
    <Carousel title={title} slides={slidesArray} columns={2} mdColumns={3} />
  )
}

export default ListRestaurantsCarousel
