import React from 'react'
import { Marker } from 'google-maps-react'
import { History } from 'history'
import defaultMarker from 'src/static/img/default_map_marker.png'

import { IRestaurant } from 'src/data/Restaurant/gql/types'
import routes from 'src/constants/routes'

const renderMapMarkers = (
  restaurants: IRestaurant[],
  history: History<History.PoorMansUnknown>
) =>
  restaurants.map((restaurant) => {
    const position: google.maps.LatLng | google.maps.LatLngLiteral | undefined =
      restaurant.info.placeLocation.coordinates[0] &&
      restaurant.info.placeLocation.coordinates[1]
        ? {
            lat: restaurant.info.placeLocation.coordinates[0],
            lng: restaurant.info.placeLocation.coordinates[1],
          }
        : undefined

    if (!position) {
      return null
    }

    return (
      <Marker
        key={`map_marker_restaurant_${restaurant.id}`}
        position={position}
        title={restaurant.name}
        icon={{
          url: restaurant.info.coverPhotoUrl || defaultMarker,
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(64, 64),
        }}
        onClick={() => {
          history.push(
            routes.restaurantDetailCompose(
              restaurant.config.customer.urlFriendlyName
            )
          )
        }}
      />
    )
  })

export default renderMapMarkers
