import React from 'react'

import * as S from './styled'

// refactored check animation used on all Pingl sites
const SuccessCheck: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='-263.5 236.5 26 26'
  >
    <S.Group>
      <circle cx='-250.5' cy='249.5' r='12' />
      <path d='M-256.46 249.65l3.9 3.74 8.02-7.8' />
    </S.Group>
  </svg>
)

export default SuccessCheck
