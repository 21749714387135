import gql from 'graphql-tag'

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    email
    name
    givenName
    familyName
    pictureUrl
    linkedSocial
    roles
    notifications {
      fcmDevices
      isMessengerEnabled
      isEmailEnabled
    }
    creditCards {
      id
      longMaskedCln
    }
    defaultCreditCard
  }
`
