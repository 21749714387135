import { TAction } from '../store'

import * as appApi from './api'

export const handleSendTestFcmNotification: TAction<boolean> = () => async (
  dispatch
) => {
  try {
    const result = await appApi.requestSendTestFcmNotification()
    return result
  } catch (e) {
    console.error(e)
    return false
  }
}
