import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Scroll to top after page transition
 */
const ScrollToTop: React.FC = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <React.Fragment>{children}</React.Fragment>
}

export default ScrollToTop
