import React from 'react'

import {
  IRestaurant,
  IRestaurantWithDistance,
} from 'src/data/Restaurant/gql/types'
import RestaurantThumbnail, {
  ThumbnailSizeProps,
} from 'src/containers/RestaurantThumbnail'

import * as S from './styled'

type Props = {
  title?: string
  restaurants: ImmutableArray<IRestaurant | IRestaurantWithDistance>
  askTableNuber: boolean
} & S.ListProps &
  ThumbnailSizeProps

const ListRestaurants: React.FC<Props> = ({
  title,
  restaurants,
  askTableNuber,
  columns = 1,
  smColumns,
  mdColumns,
  size,
}) => {
  return (
    <S.Wrap>
      {title && <S.Title>{title}</S.Title>}
      <S.List columns={columns} smColumns={smColumns} mdColumns={mdColumns}>
        {restaurants.map(
          // for some reason the restaurant sometimes happens to be undefined
          (restaurant) =>
            restaurant && (
              <RestaurantThumbnail
                data={restaurant}
                key={restaurant.id}
                askTableNumber={askTableNuber}
                size={size}
              />
            )
        )}
      </S.List>
    </S.Wrap>
  )
}

export default ListRestaurants
