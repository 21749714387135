import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'

import { P } from 'src/styles/typography'
import ErrorMessage from 'src/components/ErrorMessage'
import LocationPrompt from 'src/containers/LocationPrompt'

import * as S from './styled'

const LocationSettings: React.FC = () => {
  const [locationState, setLocationState] = useState<
    PermissionState | undefined
  >()

  const [t] = useTranslation()

  useEffect(() => {
    const setStateAsync = async () => {
      if (!navigator.geolocation) {
        setLocationState('denied')
        return
      }
      if (navigator.permissions) {
        const { state } = await navigator.permissions.query({
          name: 'geolocation',
        })
        setLocationState(state)
      } else {
        // permission status is not available
        // so the user has safari of permissions are denied
        setLocationState('prompt')
      }
    }
    setStateAsync()
  }, [])

  return (
    <>
      <P variant={'small'}>{t('profile.location.info')}</P>
      {locationState === 'prompt' && (
        <S.EnableButtonWrap>
          <LocationPrompt
            locationPermission={locationState}
            onPermissionChange={(state) => {
              setLocationState(state)
            }}
          />
        </S.EnableButtonWrap>
      )}
      {locationState === 'granted' && (
        <S.Ul>
          <S.Li>
            <span>{t('profile.location.state')}</span>
            <S.IconWrap isSuccess={true}>
              {true ? <CheckCircle /> : <Cancel />}
            </S.IconWrap>
          </S.Li>
        </S.Ul>
      )}
      {locationState === 'denied' && (
        <S.EnableButtonWrap>
          <ErrorMessage>{t('profile.location.deniedError')}</ErrorMessage>
        </S.EnableButtonWrap>
      )}
      {!locationState && <S.Placeholder />}
    </>
  )
}

export default LocationSettings
