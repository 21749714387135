// restaurant loading
export const LOAD_RESTAURANTS_REQUEST = '@restaurant/LOAD_RESTAURANTS_REQUEST'
export const LOAD_RESTAURANTS_SUCCESS = '@restaurant/LOAD_RESTAURANTS_SUCCESS'
export const LOAD_RESTAURANTS_FAILURE = '@restaurant/LOAD_RESTAURANTS_FAILURE'
export const SAVE_SELECTED_RESTAURANT_ID =
  '@restaurant/SAVE_SELECTED_RESTAURANT_ID'
export const SAVE_SELECTED_TABLE_ID = '@restaurant/SAVE_SELECTED_TABLE_ID'
export const SAVE_SELECTED_DISCOUNT = '@restaurant/SAVE_SELECTED_DISCOUNT'
export const SAVE_SELECTED_MENU_ITEM_ID =
  '@restaurant/SAVE_SELECTED_MENU_ITEM_ID'
export const LOAD_TAGS_SUCCESS = '@restaurant/LOAD_TAGS_SUCCESS'

export default {
  LOAD_RESTAURANTS_REQUEST,
  LOAD_RESTAURANTS_SUCCESS,
  LOAD_RESTAURANTS_FAILURE,
  SAVE_SELECTED_RESTAURANT_ID,
  SAVE_SELECTED_TABLE_ID,
  SAVE_SELECTED_DISCOUNT,
  LOAD_TAGS_SUCCESS,
}
