import {
  EAuthErrors,
  ENotFoundErrors,
  EOrderErrors,
  EOtherErrors,
  EPaymentErrors,
} from '../constants/errorCodes'
import i18n from '../i18n'

const tranlator = i18n.getFixedT(null, 'errors')
const t = (sth: string) => tranlator(sth)

// shared error code handling between all Pingl projects
export const mapErrorCodeToMessage = (errorCode: string) => {
  switch (errorCode) {
    /* AUTH ERRORS */
    case EAuthErrors.AccessDenied:
      return t('authErrors.accessDenied')

    case EAuthErrors.AccountExists:
      return t('authErrors.accountExists')

    case EAuthErrors.HasLinkedFacebook:
      return t('authErrors.hasLinkedFacebook')

    case EAuthErrors.HasLinkedGoogle:
      return t('authErrors.hasLinkedGoogle')

    case EAuthErrors.HasPasswordAccount:
      return t('authErrors.hasPasswordAccount')

    case EAuthErrors.InvalidCredentials:
      return t('authErrors.invalidCredentials')

    case EAuthErrors.InvalidFacebookId:
      return t('authErrors.invalidFacebookId')

    case EAuthErrors.InvalidGoogleId:
      return t('authErrors.invalidGoogleId')

    case EAuthErrors.InvalidToken:
      return t('authErrors.invalidToken')

    /* NOT FOUND ERRORS */
    case ENotFoundErrors.MenuItemNotFound:
      return t('notFoundErrors.menuItemNotFound')

    case ENotFoundErrors.OrderItemNotFound:
      return t('notFoundErrors.orderItemNotFound')

    case ENotFoundErrors.OrderNotFound:
      return t('notFoundErrors.orderNotFound')

    case ENotFoundErrors.PasswordNotFound:
      return t('notFoundErrors.passwordNotFound')

    case ENotFoundErrors.PersonNotFound:
      return t('notFoundErrors.personNotFound')

    case ENotFoundErrors.RestaurantNotFound:
      return t('notFoundErrors.restaurantNotFound')

    case ENotFoundErrors.TableNotFound:
      return t('notFoundErrors.tableNotFound')

    /* OTHERS ERRORS */
    case EOtherErrors.GenericError:
      return t('otherErrors.genericError')

    case EOtherErrors.WeakPassword:
      return t('otherErrors.weakPassword')

    case EOtherErrors.LinkedSocialAccount:
      return t('otherErrors.linkedSocialAccount')

    case EOtherErrors.PinglIsOffline:
      return t('otherErrors.pinglIsOffline')

    case EOtherErrors.InvalidRestaurantId:
      return t('otherErrors.invalidRestaurantId')

    case EOtherErrors.SpecialOfferItemAlreadyExists:
      return t('otherErrors.specialOfferItemAlreadyExists')

    case EOtherErrors.LanguageAlreadyExists:
      return t('otherErrors.languageAlreadyExists')

    case EOtherErrors.InvalidOpenTimes:
      return t('otherErrors.invalidOpenTimes')

    case EOtherErrors.WrongEmailFormat:
      return t('otherErrors.wrongEmailFormat')

    case EOtherErrors.MenuItemUpdateFail:
      return t('otherErrors.menuItemUpdateFail')

    /* ORDER ERRORS */
    case EOrderErrors.EmptyOrderNotAllowed:
      return t('orderErrors.emptyOrderNotAllowed')

    case EOrderErrors.MenuItemsNotAvailable:
      return t('orderErrors.menuItemsNotAvailable')

    /* PAYMENT ERRORS */
    case EPaymentErrors.PaymentNotInitiated:
      return t('paymentErrors.paymentNotInitiated')

    default:
      return t('defaultError')
  }
}
