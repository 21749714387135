import React from 'react'
import { CircularProgress } from '@material-ui/core'

import * as S from './styled'
import { LinkProps } from 'react-router-dom'

// props for
type CommonProps = {
  size: 'small' | 'medium' | 'large'
  variant: 'primary' | 'secondary' | 'ghost' | 'success' | 'error'
}

type ButtonProps = CommonProps & {
  type?: 'submit' | 'button'
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  fullWidth?: boolean
  isLoading?: boolean
}

type ButtonLinkProps = CommonProps & LinkProps<unknown>

const Button: React.FC<ButtonProps> = (props) => {
  const {
    size,
    variant,
    children,
    onClick,
    fullWidth = true,
    disabled,
    type = 'button',
    isLoading,
  } = props

  return (
    <S.Button
      size={size}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      hasLoader={isLoading}
    >
      {isLoading ? (
        <S.LoaderWrap variant={variant}>
          <CircularProgress />
        </S.LoaderWrap>
      ) : (
        <>{children}</>
      )}
    </S.Button>
  )
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  ...props
}) => <S.ButtonLink {...props}>{children}</S.ButtonLink>

export default Button
