import gql from 'graphql-tag'

export const MENU_ITEM_FRAGMENT = gql`
  fragment MenuItemFragment on MenuItem {
    id
    name
    imageUrl
    description
    isActive
    options {
      id
      name
      values {
        id
        name
        price
        discountedPrice
      }
    }
  }
`
