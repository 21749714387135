import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { IRestaurantWithDistance } from 'src/data/Restaurant/gql/types'
import { useReduxDispatch } from 'src/data/store'
import {
  saveSelectedRestaurantId,
  saveSelectedTableId,
} from 'src/data/Restaurant/actions'

import routes from 'src/constants/routes'
import TableNumberModal from './TableNumberModal'
import InactiveBar from './InactiveBar'

import * as S from './styled'

export type ThumbnailSizeProps = {
  size?: 'small' | 'medium' | 'large'
}

type Props = {
  data: IRestaurantWithDistance
  askTableNumber?: boolean
  showTags?: boolean
} & ThumbnailSizeProps

const RestaurantThumbnail: React.FC<Props> = ({
  data: restaurant,
  askTableNumber,
  showTags = true,
  size = 'medium',
}) => {
  const [open, setOpen] = useState(false)
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (askTableNumber) {
      if (restaurant.tables.length === 1) {
        // if there ois only one option, set is as default
        handleSetSelectedRestaurant({ tableId: restaurant.tables[0].id })
        return
      }
      setOpen(true)
    } else {
      handleSetSelectedRestaurant({ tableId: undefined })
    }
  }

  const handleSetSelectedRestaurant = ({ tableId }: { tableId?: string }) => {
    dispatch(saveSelectedTableId(tableId))
    dispatch(saveSelectedRestaurantId(restaurant.id))
    history.push(
      routes.restaurantDetailCompose(restaurant.config.customer.urlFriendlyName)
    )
  }

  const { name, distance } = restaurant

  return (
    <>
      <Link
        onClick={handleClick}
        to={routes.restaurantDetailCompose(
          restaurant.config.customer.urlFriendlyName
        )}
        title={name}
        aria-label={name}
      >
        <S.Wrap bgImage={restaurant.info.coverPhotoUrl} size={size}>
          <InactiveBar
            restaurant={restaurant}
            variant={size === 'large' ? 'floating' : 'top'}
          />
          <div>
            <S.Title size={size}>{name}</S.Title>
            {showTags && (
              <S.Tags>
                {distance !== null && distance !== undefined && (
                  <S.SingleTag>
                    {distance < 1000
                      ? `${Math.round(distance)} m`
                      : `${Math.round(distance / 100) / 10} km`}
                  </S.SingleTag>
                )}
                {restaurant.info.tags.map((tag) => (
                  <S.SingleTag key={`${restaurant.id}_${tag.id}`}>
                    {tag.name}
                  </S.SingleTag>
                ))}
              </S.Tags>
            )}
          </div>
        </S.Wrap>
      </Link>
      {open && (
        <TableNumberModal
          restaurant={restaurant}
          open={open}
          handleClose={() => setOpen(false)}
          onSuccess={handleSetSelectedRestaurant}
        />
      )}
    </>
  )
}

export default RestaurantThumbnail
