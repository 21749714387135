import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors, media } from 'src/styles'
import { P, H1 as BaseH1 } from 'src/styles/typography'
import ErrorMessage from 'src/components/ErrorMessage'

export const Wrap = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.phone`
    width: 80%;
  `}
`

export const InnerWrap = styled.div``

export const H1 = styled(BaseH1)`
  margin-bottom: 16px;
`

export const ButtonsWrap = styled.div`
  margin-top: 16px;

  & > * {
    margin: 8px 0;
  }
`

export const ErrorMsg = styled(ErrorMessage)`
  margin-bottom: 20px;
`

export const ForgotPasswordWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-right: 8px;
`

export const ForgotPassword = styled(Link)`
  color: ${colors.gray_500};
  font-size: 0.9rem;
`

export const TermsParagraph = styled((props) => (
  <P {...props} variant='small' />
))`
  margin-top: 32px;
`
