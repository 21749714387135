import { TAction } from '../store'
import {
  loadRestaurantsRequest,
  loadRestaurantsSuccess,
  loadRestaurantsFailure,
  saveSelectedRestaurantId,
  saveSelectedTableId,
  saveSelectedDiscount,
  loadTagsSuccess,
  saveSelectedMenuItemId,
} from './actions'
import * as restaurantApi from './api'
import { IRestaurant } from './gql/types'
import { QRCodeParams } from 'src/utils/qrCodeUtils'

export const handleGetRestaurants: TAction<void> = () => async (dispatch) => {
  dispatch(loadRestaurantsRequest())
  try {
    const result = await restaurantApi.requestGetRestaurants({})
    dispatch(loadRestaurantsSuccess(result))
  } catch (e) {
    dispatch(loadRestaurantsFailure())
  }
}

export const handleGetTags: TAction<void> = () => async (dispatch) => {
  try {
    const result = await restaurantApi.requestGetAllTags()
    dispatch(loadTagsSuccess(result))
  } catch (e) {
    console.error(e)
  }
}

export const handleGetRestaurantByParams: TAction<
  IRestaurant | null,
  QRCodeParams
> = ({ tableId, discountCode, restaurantSlug }) => async (dispatch) => {
  try {
    let restaurant: IRestaurant | null = null

    // handle tableId
    if (tableId) {
      restaurant = await restaurantApi.requestGetRestaurantByTableId({
        tableId,
      })
      dispatch(saveSelectedTableId(tableId))
    } else {
      dispatch(saveSelectedTableId(undefined))
    }

    // handle restaurantSlug
    if (!restaurant && restaurantSlug) {
      restaurant = await restaurantApi.requestGetRestaurantBySlug({
        name: restaurantSlug,
      })
    }

    // handle discountCode
    if (restaurant && discountCode) {
      try {
        const discount = await restaurantApi.requestGetDiscountByCode({
          code: discountCode,
          restaurantId: restaurant.id,
        })
        dispatch(saveSelectedDiscount(discount))
      } catch (err) {
        // discount is not valid
        dispatch(saveSelectedDiscount(undefined))
      }
    } else {
      dispatch(saveSelectedDiscount(undefined))
    }

    // save the selected restaurant or undefined
    dispatch(saveSelectedRestaurantId(restaurant ? restaurant?.id : undefined))

    // currently there is no option to set menuItem by url, but can be added in the future
    dispatch(saveSelectedMenuItemId(undefined))

    return restaurant
  } catch (e) {
    // if the user is offline, try to validate the code using only cahced data
    if (e.networkError) {
      return await dispatch(
        handleGetRestaurantByParamsWhileOffline({
          tableId,
          discountCode,
          restaurantSlug,
        })
      )
    }
    console.log(e)
    return null
  }
}

export const handleGetRestaurantByParamsWhileOffline: TAction<
  IRestaurant | null,
  QRCodeParams
> = ({ tableId, discountCode, restaurantSlug }) => async (
  dispatch,
  getState
) => {
  try {
    let restaurant: IRestaurant | null = null

    const cachedRestaurants = getState().restaurant.restaurants

    // handle tableId
    if (tableId) {
      restaurant =
        cachedRestaurants.find((res) =>
          res.tables.find((t) => t.id === tableId)
        ) || null
      if (!restaurant) {
        // the place was not found, but it can maybe exist, user has to go online
        throw new Error('You have to go online')
      }
      dispatch(saveSelectedTableId(tableId))
    } else {
      dispatch(saveSelectedTableId(undefined))
    }

    // handle restaurantSlug
    if (!restaurant && restaurantSlug) {
      restaurant =
        cachedRestaurants.find(
          (res) => res.config.customer.urlFriendlyName === restaurantSlug
        ) || null
    }

    // handle discountCode
    if (restaurant && discountCode) {
      // ignore discountCode, there is no way to validate it offline
      dispatch(saveSelectedDiscount(undefined))
    } else {
      dispatch(saveSelectedDiscount(undefined))
    }

    // save the selected restaurant or undefined
    dispatch(saveSelectedRestaurantId(restaurant ? restaurant?.id : undefined))

    // currently there is no option to set menuItem by url, but can be added in the future
    dispatch(saveSelectedMenuItemId(undefined))

    return restaurant
  } catch (e) {
    console.log(e)
    throw e
  }
}
