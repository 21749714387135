import React from 'react'
import styled from 'styled-components'
import { P as BaseP, H1 as BaseH1 } from 'src/styles/typography'
import ErrorMessage from 'src/components/ErrorMessage'
import { media } from 'src/styles'

export const Wrap = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.phone`
    width: 80%;
  `}
`

export const InnerWrap = styled.div``

export const H1 = styled(BaseH1)`
  margin-bottom: 8px;
`

export const P = styled(BaseP)`
  margin-bottom: 16px;
`

export const ButtonsWrap = styled.div`
  margin-top: 16px;

  & > * {
    margin: 8px 0;
  }
`

export const ErrorMsg = styled(ErrorMessage)`
  margin-bottom: 20px;
`

export const TermsParagraph = styled((props) => (
  <BaseP {...props} variant='small' />
))`
  margin-top: 32px;
`
