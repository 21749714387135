export interface IMediaBreakpoints {
  phone: number
  tablet: number
  desktop: number
  bigDesktop: number
}

const breakpoints: IMediaBreakpoints = {
  bigDesktop: 1800,
  desktop: 1200,
  phone: 600,
  tablet: 900,
}

export { breakpoints }
