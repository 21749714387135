import React from 'react'
import { PINGL_AREAS as places } from 'src/utils/locationUtils'

import { ButtonLocationLink, LocationLinksWrap } from './styled'

type Props = {
  setMapCenter: (latlng: google.maps.LatLngLiteral) => void
}

const LocationLinks: React.FC<Props> = ({ setMapCenter }) => {
  const clickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    latlng: google.maps.LatLngLiteral
  ) => {
    e.stopPropagation()
    setMapCenter(latlng)
  }

  return (
    <LocationLinksWrap>
      {places
        .filter((place) => place.showInMap)
        .map((place) => (
          <ButtonLocationLink
            key={`locationLink_${place.name}`}
            onClick={(e) => clickHandler(e, { lat: place.lat, lng: place.lng })}
          >
            {place.name}
          </ButtonLocationLink>
        ))}
    </LocationLinksWrap>
  )
}

export default LocationLinks
