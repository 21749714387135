import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Reader from 'react-qr-reader'

import * as S from './styled'
import { parseQrCodeUrl, QRCodeParams } from 'src/utils/qrCodeUtils'
import SuccessCheck from 'src/static/SVG/SuccessCheck'
import ErrorMessage from 'src/components/ErrorMessage'

export enum ScanResult {
  'SUCCESS' = 'SUCCESS',
  'OFFLINE' = 'OFFLINE',
  'WRONG_CODE' = 'WRONG_CODE',
}

type Props = {
  handleScanResult: (result: QRCodeParams) => Promise<ScanResult>
}

const QrReader: React.FC<Props> = ({ handleScanResult }) => {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [scanResult, setScanResult] = useState({
    success: false,
    error: '',
  })

  const readerElement = useRef<QrReader>(null)

  const handleScan = async (data: string | null) => {
    // called only once - when success, don't call request
    if (data && !isLoading && !scanResult.success && !scanResult.error) {
      setIsLoading(true)
      setScanResult({ success: false, error: '' })
      const params = parseQrCodeUrl(data)
      const result = await handleScanResult(params)
      if (result === ScanResult.SUCCESS) {
        // on success
        setScanResult({ success: true, error: '' })
        // force update to take effect of classname change
        readerElement.current?.forceUpdate()
      } else if (result === ScanResult.OFFLINE) {
        showError(t('scanner.offline'))
      } else {
        showError(t('scanner.wrongCode'))
      }
      setIsLoading(false)
    }
  }

  const handleError = (err: any) => {
    console.error('QrReader error', err)
  }

  const showError = (message: string) => {
    console.log('snanning error ', message)
    setScanResult({ success: false, error: message })
    setTimeout(() => {
      setScanResult((state) => ({ ...state, error: '' }))
    }, 3000)
  }

  return (
    <S.Wrap>
      <S.TitleWrap>
        <S.Title>{t('scanner.callToAction')}</S.Title>
      </S.TitleWrap>
      <Reader
        className={`scanner ${scanResult.success ? 'scanner--success' : ''}`}
        ref={readerElement}
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          right: '0',
        }}
      />
      {scanResult.success && (
        <S.IcoWrap>
          <SuccessCheck />
        </S.IcoWrap>
      )}
      {scanResult.error && (
        <S.ErrorWrap>
          <ErrorMessage>{scanResult.error}</ErrorMessage>
        </S.ErrorWrap>
      )}
    </S.Wrap>
  )
}

export default QrReader
