import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isStandalone } from 'src/utils/pwaUtils'

import * as S from './styled'

/**
 * Add to Home Screen button
 * Is shown only when the app is not installed & in Chrome
 */
const AddToHomeScreen: React.FC = () => {
  const [t] = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [
    deferredPrompt,
    setDeferredPrompt,
  ] = useState<BeforeInstallPromptEvent | null>(null)

  useEffect(() => {
    // save the prompt for use in button
    const promptListener = (e: Event) => {
      const deferredPrompt = e as BeforeInstallPromptEvent
      setDeferredPrompt(deferredPrompt)

      // show the button
      if (!isStandalone() && deferredPrompt) {
        setIsVisible(true)
      }
    }

    window.addEventListener('beforeinstallprompt', promptListener)

    return () => {
      // cleanup
      window.removeEventListener('beforeinstallprompt', promptListener)
    }
  }, [])

  const clickHandler = async () => {
    if (!deferredPrompt) {
      return
    }

    // prompt the user
    deferredPrompt.prompt()

    // wait for the user response
    const choiceResult = await deferredPrompt.userChoice
    if (choiceResult.outcome === 'accepted') {
      console.log('User has installed the app')
    } else {
      console.log('User dismissed the installation')
    }
    setIsVisible(false)
    setDeferredPrompt(null)
  }

  return isVisible ? (
    <S.Wrap onClick={clickHandler}>
      <div>
        <S.Title>{t('addToHomeScreen.title')}</S.Title>
        <S.Text>{t('addToHomeScreen.text')}</S.Text>
      </div>
      <S.ActionButton>{t('addToHomeScreen.button')}</S.ActionButton>
    </S.Wrap>
  ) : null
}

export default AddToHomeScreen
