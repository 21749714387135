import React from 'react'

type Props = {
  inverted?: boolean
}

const PinglLogo: React.FC<Props> = ({ inverted }) => {
  const color = inverted ? '#fff' : '#30323C'
  return (
    <svg width='40' height='35' viewBox='0 0 159 68'>
      <g fill='none'>
        <path
          d='M20.9 14.5C30.7 14.5 37.3 21.6 37.3 32.3 37.3 42.7 30.9 49.8 21 49.8 15.8 49.8 11.7 47.7 9.2 43.8L9.2 67 0.7 67 0.7 14.7 9.2 14.7 9.2 20.4C11.7 16.6 15.7 14.5 20.9 14.5ZM19 42.5C24.7 42.5 28.8 38.2 28.8 32 28.8 25.9 24.7 21.6 19 21.6 13.2 21.6 9.2 25.9 9.2 32 9.2 38.3 13.2 42.5 19 42.5Z'
          fill={color}
        />
        <polygon
          points='45.1 14.7 53.5 14.7 53.5 49.5 45.1 49.5'
          fill={color}
        />
        <rect width='8.5' height='8.5' x='45' y='0.5' fill={color} />
        <path
          d='M85.3 14.4C93.3 14.4 98.1 19.5 98.1 27.8L98.1 49.5 89.6 49.5 89.6 30.3C89.6 25.4 86.7 22.4 81.9 22.4 76.2 22.5 72.8 26.7 72.8 32.4L72.8 49.5 64.3 49.5 64.3 14.7 72.8 14.7 72.8 21.2C75.3 16.6 79.6 14.5 85.3 14.4zM131.3 14.7L139.8 14.7 139.8 50.7C139.8 60.6 132.6 66.9 121.6 66.9 116 66.9 111 65.1 106.9 62.3L110.3 56.2C113.5 58.6 117 59.9 121.1 59.9 127.4 59.9 131.3 56.4 131.3 50.9L131.3 42.3C129 45.8 125.2 47.7 120.3 47.7 111.1 47.7 104.8 41 104.8 31 104.8 21.2 111 14.5 120 14.5 125 14.4 128.9 16.4 131.3 19.9L131.3 14.7zM122.1 41.1C127.6 41.1 131.3 37 131.3 31.2 131.3 25.3 127.6 21.2 122.1 21.2 116.7 21.2 112.9 25.4 112.8 31.2 112.9 37 116.6 41.1 122.1 41.1z'
          fill={color}
        />
        <polygon
          points='150.4 1.3 158.9 1.3 158.9 49.5 150.4 49.5'
          fill={color}
        />
        <polygon points='18.5 58.6 98.3 58.6 98.3 67 18.5 67' fill='#F4282D' />
      </g>
    </svg>
  )
}

export default PinglLogo
