import { css, CSSObject } from 'styled-components'
import { breakpoints } from 'src/styles/dimensions'

const mediaQuery = (
  first: TemplateStringsArray | CSSObject,
  ...query: Array<TemplateStringsArray | CSSObject | number>
) => (...rules: Parameters<typeof css>) => css`
  @media ${css(first, ...query)} {
    ${css(...rules)}
  }
`

// helper functions to generate media queries for any size bigger than:
const media = {
  bigDesktop: mediaQuery`(min-width: ${breakpoints.bigDesktop / 16}em)`,
  desktop: mediaQuery`(min-width: ${breakpoints.desktop / 16}em)`,
  tablet: mediaQuery`(min-width: ${breakpoints.tablet / 16}em)`,
  phone: mediaQuery`(min-width: ${breakpoints.phone / 16}em)`,
}

export { media }
