import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'
import { IRestaurant, IDiscount, ITag } from './gql/types'
import {
  LOAD_RESTAURANTS_SUCCESS,
  SAVE_SELECTED_RESTAURANT_ID,
  SAVE_SELECTED_TABLE_ID,
  SAVE_SELECTED_DISCOUNT,
  LOAD_TAGS_SUCCESS,
  SAVE_SELECTED_MENU_ITEM_ID,
} from './constants'

export type IRestaurantActions = ActionType<typeof import('./actions')>

export type IRestaurantState = ImmutableObject<{
  restaurants: IRestaurant[]
  selectedId?: string
  selectedTableId?: string
  selectedMenuItemId?: string
  selectedDiscount?: IDiscount
  tags: ITag[]
}>

const initState: IRestaurantState = {
  restaurants: [],
  selectedId: undefined,
  selectedTableId: undefined,
  selectedMenuItemId: undefined,
  selectedDiscount: undefined,
  tags: [],
}

const restaurantReducer: Reducer<IRestaurantState, IRestaurantActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case LOAD_RESTAURANTS_SUCCESS:
      return { ...state, restaurants: action.payload }
    case SAVE_SELECTED_RESTAURANT_ID:
      return { ...state, selectedId: action.payload }
    case SAVE_SELECTED_TABLE_ID:
      return { ...state, selectedTableId: action.payload }
    case SAVE_SELECTED_DISCOUNT:
      return { ...state, selectedDiscount: action.payload }
    case SAVE_SELECTED_MENU_ITEM_ID:
      return { ...state, selectedMenuItemId: action.payload }
    case LOAD_TAGS_SUCCESS:
      return { ...state, tags: action.payload }
    default:
      return state
  }
}

export default restaurantReducer
