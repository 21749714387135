enum requiredVariables {
  'REACT_APP_VERSION',
  'REACT_APP_ENV',
  'REACT_APP_HOST_URL',
  'REACT_APP_HOST_URL_WS',
  'REACT_APP_GOOGLE_MAPS_KEY',
}

type RequiredVariables = keyof typeof requiredVariables
type Config = Readonly<{ [key in RequiredVariables]: string }>

// add extra values that are not in .env files
const extraConfig: Readonly<{ [key: string]: string }> = {
  REACT_APP_VERSION: '1.0.5',
}

// ensure that all of the env vars are provided
// credit fot this idea goes to Peter, my STRV React Nights mentor (https://github.com/varholak-peter)
const ensureConfig = (env: NodeJS.ProcessEnv): Config => {
  const config = { ...env, ...extraConfig }
  return Object.values(requiredVariables).reduce((acc, val) => {
    // Filters index entries from enum.
    if (typeof val === 'number') {
      return acc
    }

    if (!config[val]) {
      throw new Error(`Missing '${val}' environment variable.`)
    }

    return { ...acc, [val]: config[val] }
  }, ({} as any) as Config)
}

export default ensureConfig(process.env)
