import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { Form, Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { IRestaurant } from 'src/data/Restaurant/gql/types'
import TextField from 'src/components/TextField'
import Button from 'src/components/Button'

import * as S from './styled'

type FormValues = {
  tableNumber: string
}

const validationSchema = (t: TFunction) =>
  object<FormValues>({
    tableNumber: string().required(t('forms.errors.required')),
  })

type Props = {
  restaurant: IRestaurant
  open: boolean
  handleClose: () => void
  onSuccess: (args: { tableId?: string }) => void
}

const TableNumberModal: React.FC<Props> = ({
  restaurant,
  open,
  handleClose,
  onSuccess,
}) => {
  const [t] = useTranslation()

  const handleSubmit = async (
    values: FormValues,
    { setFieldError, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true)
    const { tableNumber } = values
    const table = restaurant.tables.find(
      (table) => table.name.toLowerCase() === tableNumber.toLowerCase()
    )
    if (table) {
      onSuccess({ tableId: table.id })
    } else {
      setFieldError('tableNumber', t('forms.errors.tableNotFound'))
    }
    setSubmitting(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-table-number'
    >
      <S.DialogHeader>
        <DialogTitle id='form-dialog-title'>
          {t('forms.table.dialogTitle')} - {restaurant.name}
        </DialogTitle>

        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </S.DialogHeader>
      <DialogContent>
        <DialogContentText>{t('forms.table.dialogText')}</DialogContentText>
        <S.FormWrap>
          <Formik<FormValues>
            onSubmit={handleSubmit}
            validationSchema={validationSchema(t)}
            initialValues={{ tableNumber: '' }}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextField
                  name='tableNumber'
                  type='text'
                  label={t('forms.table.number')}
                  autoFocus={true}
                />

                <Button
                  type='submit'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  variant='primary'
                  size='medium'
                >
                  {t('forms.table.submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </S.FormWrap>
      </DialogContent>
    </Dialog>
  )
}

export default TableNumberModal
