import gql from 'graphql-tag'
import { PERSON_FRAGMENT } from './fragments'

export const SIGN_IN = gql`
  mutation signIn($username: String!, $password: String) {
    signIn(username: $username, password: $password) {
      user {
        ...PersonFragment
      }
      token
    }
  }
  ${PERSON_FRAGMENT}
`

export const EMAIL_SIGN_UP = gql`
  mutation signUp(
    $username: String!
    $password: String!
    $givenName: String!
    $familyName: String!
  ) {
    signUp(
      username: $username
      password: $password
      givenName: $givenName
      familyName: $familyName
    ) {
      user {
        ...PersonFragment
      }
      token
    }
  }
  ${PERSON_FRAGMENT}
`

export const SEND_FCM_TOKEN = gql`
  mutation sendFCMToken($token: String!, $deviceUid: String!) {
    sendFCMToken(token: $token, deviceUid: $deviceUid)
  }
`

export const SET_EMAIL_NOTIF_ENABLED = gql`
  mutation setPersonNotificationsIsEmailEnabled($isEnabled: Boolean!) {
    setPersonNotificationsIsEmailEnabled(isEnabled: $isEnabled)
  }
`
