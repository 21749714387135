import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import { colors } from 'src/styles'
import { composeEasing, easeInOutBack } from 'src/styles/animations'

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 32px 0;
`

export const Link = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90px;

  text-align: center;
  line-height: 1.3rem;
  white-space: pre-line;

  & > div {
    transition: transform 200ms ${composeEasing(easeInOutBack)};
  }

  &:hover {
    & > div {
      transform: scale(1.05);
    }
  }
`

export const IconWrap = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 70px;

  margin-bottom: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${colors.secondary};
  color: ${colors.primary};
`
