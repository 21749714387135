import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useReduxDispatch } from 'src/data/store'
import {
  saveSelectedRestaurantId,
  saveSelectedTableId,
  saveSelectedMenuItemId,
} from 'src/data/Restaurant/actions'
import { IRestaurantMenuItem } from 'src/data/MenuItems/gql/types'

import routes from 'src/constants/routes'

import * as S from '../RestaurantThumbnail/styled'
import { IRestaurant } from 'src/data/Restaurant/gql/types'

export type ThumbnailSizeProps = {
  size?: 'small' | 'medium' | 'large'
}

type Props = {
  menuItem: IRestaurantMenuItem
  restaurant?: IRestaurant | null
} & ThumbnailSizeProps

const MenuItemThumbnail: React.FC<Props> = ({
  menuItem,
  restaurant,
  size = 'medium',
}) => {
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    dispatch(saveSelectedTableId(undefined))
    dispatch(saveSelectedRestaurantId(menuItem.restaurantId))
    dispatch(saveSelectedMenuItemId(menuItem.id))
    history.push(
      routes.restaurantDetailCompose(
        restaurant?.config.customer.urlFriendlyName || ''
      )
    )
  }

  const distance = undefined
  const showTags = !!restaurant || distance

  return (
    <>
      <Link
        onClick={handleClick}
        to={routes.restaurantDetailCompose(
          restaurant?.config.customer.urlFriendlyName || ''
        )}
        aria-label={menuItem.name}
      >
        <S.Wrap bgImage={menuItem.imageUrl || ''} size={size}>
          <div>
            <S.Title size={size}>{menuItem.name}</S.Title>
            {showTags && (
              <S.Tags>
                {restaurant && <S.SingleTag>{restaurant.name}</S.SingleTag>}
                {distance !== null && distance !== undefined && (
                  <S.SingleTag>
                    {distance < 1000
                      ? `${Math.round(distance)} m`
                      : `${Math.round(distance / 100) / 10} km`}
                  </S.SingleTag>
                )}
              </S.Tags>
            )}
          </div>
        </S.Wrap>
      </Link>
    </>
  )
}

export default MenuItemThumbnail
