import gql from 'graphql-tag'

export const RESTAURANT_FRAGMENT = gql`
  fragment RestaurantFragment on RestaurantPublic {
    id
    name
    info {
      phone
      website
      websiteUrl
      instagramUrl
      facebookUrl
      coverPhotoUrl
      placeAddress
      placeLocation {
        coordinates
      }
      tags {
        id
        name
      }
    }
    tables {
      id
      name
      orderType
    }
    config {
      languages {
        locale
      }
      customer {
        tipsEnabled
        urlFriendlyName
      }
      openTimes {
        day
        time {
          open {
            hour
            minute
          }
          close {
            hour
            minute
          }
        }
      }
      isOpen
      isActive
      orderTypes
    }
  }
`

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on DiscountPublic {
    id
    name
    code
  }
`

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    name
  }
`
