import React from 'react'

import * as S from './styled'
import PageLayout from 'src/components/PageLayout'
import { Grid } from '@material-ui/core'
import { WithTranslation, withTranslation } from 'react-i18next'

interface IState {
  error: any
}

interface IProps extends WithTranslation {
  children: React.ReactNode
  showFallbackUI?: boolean
  fallbackUI?: React.ReactNode
}

class ErrorBoundary extends React.Component<IProps, IState> {
  public state: IState = { error: null }

  public componentDidCatch(error: Error | null, errorInfo: any) {
    this.setState({ error })
    // error can be reported to error reporting service here
  }

  public refreshPage() {
    window.location.reload()
  }

  public clearCache() {
    localStorage.clear()
    window.location.replace('/')
  }

  public render() {
    const { t, showFallbackUI, fallbackUI } = this.props

    if (this.state.error) {
      // render fallback UI
      return showFallbackUI === false
        ? null
        : fallbackUI || (
            <PageLayout showBackButton={false} showProfileButton={false}>
              <S.Wrap>
                <S.Title>{t('internalError.title')}</S.Title>
                <S.Text>{t('internalError.text')}</S.Text>
                <S.ActionsWrap>
                  <Grid container>
                    <Grid item xs={12} sm={5}>
                      <S.MainButton
                        variant='primary'
                        size='medium'
                        onClick={this.refreshPage}
                      >
                        {t('internalError.reload')}
                      </S.MainButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '64px',
                      }}
                    >
                      {t('internalError.orDivider')}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <S.MainButton
                        variant='ghost'
                        size='medium'
                        onClick={this.clearCache}
                      >
                        {t('internalError.clearCache')}
                      </S.MainButton>
                    </Grid>
                  </Grid>
                </S.ActionsWrap>
              </S.Wrap>
            </PageLayout>
          )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default withTranslation()(ErrorBoundary)
