import React from 'react'

import LocationPrompt from 'src/containers/LocationPrompt'
import * as S from './styled'

const LocationHandler: React.FC = () => {
  return (
    <S.EnableButtonWrap>
      <LocationPrompt />
    </S.EnableButtonWrap>
  )
}

export default LocationHandler
