import styled from 'styled-components'
import { P } from 'src/styles/typography'
import { composeEasing, easeInOutBack } from 'src/styles/animations'

export const Wrap = styled.div``

export const UpWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 300px;
  margin: 0 auto;

  cursor: pointer;
  padding: 16px;

  svg {
    transition: transform 200ms ${composeEasing(easeInOutBack)};
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`

export const UpText = styled(P)``

export const IconWrap = styled.div`
  transform: rotate(-90deg);
`

export const Text = styled(P)`
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
`
