import { matchPath } from 'react-router-dom'

const privateRoutes = {
  profile: '/profile',
  orderHistory: '/order-history',
}

const publicRoutes = {
  index: '/',
  login: '/login',
  signup: '/signup',
  scanner: '/scan-qr',
  explore: '/explore-restaurants/',
  map: '/map',
  restaurantDetail: '/restaurant/:slug',
  restaurantDetailCompose: (slug: string) => `/restaurant/${slug}`,
}

const externalRoutes = {}

const routes = { ...privateRoutes, ...publicRoutes, external: externalRoutes }

export const isRoutePrivate = (path: string) => {
  return !!Object.values(privateRoutes).find(
    (r) => !!matchPath(path, { path: r, exact: true })
  )
}

export default routes
