import styled, { css, keyframes } from 'styled-components'

import { colors, media } from 'src/styles'

export const Wrap = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  z-index: 100000;

  /* style google map zoom button */
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 220px !important;

    ${media.phone`
      bottom: 200px !important;
    `}
  }

  /* Style restaurant pin */
  .gm-style img[src*='restaurant'],
  .gm-style img[src*='data:image/png;base64'] {
    border-radius: 100%;
    border: 5px solid ${colors.alpha} !important;
  }

  ${media.phone`
    height: 100%;
  `}
`

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 1px #f5292d50;
  }

  50% {
    box-shadow: 0 0 0 16px #f5292d00;
  }

  100% {
    box-shadow: 0 0 0 16px #f5292d00;
  }
`

export const PinWrap = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 40px;
  background: ${colors.alpha};

  animation: ${pulse} 4s linear infinite;
`

export const Perimeter = styled.div<{ visible: boolean }>`
  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) rotate(37deg);

  border: 2px solid #80808059;
  border-radius: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  transition-duration: 0.2s;
  transition-property: transform, opacity;

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      transform: translate(-50%, -50%) scale(0) rotate(37deg);

      transition-duration: 0.2s;
      transition-property: transform, opacity;
    `};
`

export const PerimeterRange = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.gray_500};
  color: ${colors.primary};
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  min-width: 55px;
  text-align: center;
`

export const MainButtonWrap = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;

  ${media.phone`
    bottom: 10px;
  `}
`

export const TopBar = styled.div`
  top: 8px;
  left: 8px;
  right: 8px;
  position: absolute;
  z-index: 100;

  display: flex;
`

export const LocationLinksWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > *:not(:first-child) {
    margin-left: 10px;
    margin-bottom: 10px;
  }
`

const MapButton = styled.button`
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.primary};
  color: ${colors.secondary};
  border-radius: 100%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

  transition: background-color 200ms;

  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${colors.gray_100};
  }
`

export const ButtonBack = styled(MapButton)`
  ${media.phone`
    display: none;
  `}
`

export const ButtonLocationLink = styled(MapButton)`
  width: auto;
  padding: 0 20px;
  border-radius: 50px;
  font-size: 1.2em;
`

export const ButtonLocator = styled(MapButton)`
  position: absolute;
  z-index: 100;
  right: 10px;
  bottom: 90px;

  ${media.phone`
    bottom: 70px;
  `}
`
