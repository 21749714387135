/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum LinkedSocial {
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  NONE = "NONE",
}

export enum OrderClass {
  TakeAway = "TakeAway",
  ToTable = "ToTable",
}

export enum OrderType {
  Delivery = "Delivery",
  PickUp = "PickUp",
  TakeAway = "TakeAway",
}

export enum Role {
  ADMIN = "ADMIN",
  RESTAURANT = "RESTAURANT",
  USER_COMPLETE = "USER_COMPLETE",
}

export interface RestaurantFilter {
  type: OrderClass;
  tagIds: string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
