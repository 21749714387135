import styled, { css } from 'styled-components'
import { H3 } from 'src/styles/typography'
import { animations, radius, colors, media } from 'src/styles'
import { ThumbnailSizeProps } from '.'

export type IWrapProps = {
  bgImage: string
} & ThumbnailSizeProps

export const Wrap = styled.div<IWrapProps>`
  position: relative;

  border-radius: ${radius.primary};
  background: ${colors.gray_100};
  ${({ bgImage, size }) =>
    bgImage &&
    (size !== 'large'
      ? css`
          background-image: linear-gradient(
            5deg,
            rgba(0, 0, 0, 0.85) 10%,
            rgba(0, 0, 0, 0.40) 50%,
            rgba(0, 0, 0, 0) 70%
          ), url('${bgImage}');
      `
      : css`
          background-image: linear-gradient(
            60deg,
            rgba(0, 0, 0, 0.8) 0,
            rgba(0, 0, 0, 0.7) 30%,
            rgba(0, 0, 0, 0) 70%
          ), url('${bgImage}');
        `)}

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  ${({ size }) =>
    animations.scaleOnHover(size === 'large' ? 1.008 : undefined)};

  padding: 16px 8px;
  ${media.tablet`
    padding: 16px;
  `}

  ${({ size }) =>
    size === 'small'
      ? css`
          height: 100px;
          padding: 4px 8px;
        `
      : size === 'medium'
      ? css`
          height: 200px;
        `
      : size === 'large'
      ? css`
          height: 230px;
          padding: 64px 32px !important;
          ${media.phone`
            height: 300px;
          `}
        `
      : ''}
`

export const Title = styled(H3)<ThumbnailSizeProps>`
  color: #fff;
  margin-bottom: 8px;

  text-shadow: 0 0 15px rgba(0, 0, 0, 0.7);

  ${({ size }) =>
    size === 'small'
      ? css`
          font-size: 1.3rem;
          line-height: 1.5rem;
        `
      : size === 'large'
      ? css`
          font-size: 3rem;
        `
      : ''}
`

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 22px;
  overflow: hidden;
`

export const SingleTag = styled.li`
  padding: 4px 8px;
  margin-right: 8px;

  border-radius: ${radius.secondary};
  background-color: rgba(50, 50, 50, 0.8);

  color: ${colors.primary};
`
