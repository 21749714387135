import React from 'react'

import Carousel from '../Carousel'
import MenuItemThumbnail from 'src/containers/MenuItemThumbnail'
import { IRestaurantMenuItem } from 'src/data/MenuItems/gql/types'
import { IRestaurant } from 'src/data/Restaurant/gql/types'

type Props = {
  title?: string
  menuItems: ImmutableArray<IRestaurantMenuItem>
  restaurants: ImmutableArray<IRestaurant>
}

const ListMealsCarousel: React.FC<Props> = ({
  title,
  menuItems,
  restaurants,
}) => {
  const slidesArray = menuItems.map((menuItem) => {
    const res = restaurants.find((r) => r.id === menuItem.restaurantId)
    return {
      element: <MenuItemThumbnail menuItem={menuItem} restaurant={res} />,
      key: menuItem.restaurantId,
    }
  })

  return (
    <Carousel
      title={title}
      slides={slidesArray}
      columns={3}
      smColumns={4}
      mdColumns={5}
    />
  )
}

export default ListMealsCarousel
