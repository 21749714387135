import gql from 'graphql-tag'

import { PERSON_FRAGMENT } from './fragments'

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const REFRESH_TOKEN = gql`
  query refreshToken {
    refreshToken
  }
`
