import React from 'react'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { useTranslation } from 'react-i18next'

import config from 'src/config'

import * as S from './styled'

const BottomBar: React.FC = () => {
  const [t] = useTranslation()
  const handleUpButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <S.Wrap>
      <S.UpWrap onClick={handleUpButtonClick}>
        <S.IconWrap>
          <ArrowForwardIos />
        </S.IconWrap>
        <S.UpText>{t('main.backToTop')}</S.UpText>
      </S.UpWrap>
      <S.Text variant='small'>
        {t('main.appVersion')}: {config.REACT_APP_VERSION}
      </S.Text>
    </S.Wrap>
  )
}

export default BottomBar
