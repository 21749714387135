import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'
import NotificationsActive from '@material-ui/icons/NotificationsActive'

import {
  isPushNotifCapable,
  generateDeviceUid,
} from 'src/utils/notificationsUtils'
import { selectUserNotificationSettings } from 'src/data/User/selectors'
import { P } from 'src/styles/typography'
import { useReduxDispatch } from 'src/data/store'
import { handleEnableEmailNotifications } from 'src/data/User/thunks'
import { handleSendTestFcmNotification } from 'src/data/App/thunks'

import { generateAndSaveFirebaseToken } from 'src/utils/firebase'
import ButtonBanner from 'src/components/ButtonBanner'
import ErrorMessage from 'src/components/ErrorMessage'

import * as S from './styled'

type PushNotifState = NotificationPermission | 'unsupported' | 'ready'

const NotificationsSettings: React.FC = () => {
  const [t] = useTranslation()
  const dispatch = useReduxDispatch()
  const settings = useSelector(selectUserNotificationSettings)
  const pushNotifCapable = isPushNotifCapable()

  // check if is this device ready for push notifications
  const [notificationState, setNotificationState] = useState<PushNotifState>(
    () => {
      if (!pushNotifCapable) {
        return 'unsupported'
      }
      const permission = Notification.permission
      if (
        permission === 'granted' &&
        settings?.fcmDevices.includes(
          generateDeviceUid(settings?.fcmDevices || [])
        )
      ) {
        return 'ready'
      }
      return permission
    }
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleEnablePushClick = async () => {
    setIsLoading(true)
    const result = await generateAndSaveFirebaseToken()
    if (result) {
      setNotificationState('ready')
    } else {
      if (Notification.permission === 'denied') {
        setNotificationState('denied')
      }
    }
    setIsLoading(false)
  }

  const handleTestPushClick = async () => {
    setIsLoading(true)
    await dispatch(handleSendTestFcmNotification())
    setIsLoading(false)
  }

  const handleEnableEmailClick = async () => {
    setIsLoading(true)
    await dispatch(handleEnableEmailNotifications())
    setIsLoading(false)
  }

  return (
    <>
      <P variant={'small'}>{t('profile.notifications.info')}</P>
      {(notificationState === 'default' || notificationState === 'granted') && (
        <S.EnableButtonWrap>
          <ButtonBanner
            variant='normal'
            icon={<NotificationsActive />}
            onClick={handleEnablePushClick}
            buttonText={t('profile.notifications.enableButton')}
            isLoading={isLoading}
          >
            {t('profile.notifications.enable')}
          </ButtonBanner>
        </S.EnableButtonWrap>
      )}
      {notificationState === 'ready' && (
        <S.EnableButtonWrap>
          <ButtonBanner
            variant='success'
            icon={<NotificationsActive />}
            onClick={handleTestPushClick}
            buttonText={t('profile.notifications.testButton')}
            isLoading={isLoading}
          >
            {t('profile.notifications.test')}
          </ButtonBanner>
        </S.EnableButtonWrap>
      )}
      {notificationState === 'denied' && (
        <ErrorMessage>{t('profile.notifications.deniedError')}</ErrorMessage>
      )}
      {notificationState === 'unsupported' && !settings?.isEmailEnabled && (
        <S.EnableButtonWrap>
          <ButtonBanner
            variant='normal'
            icon={<NotificationsActive />}
            onClick={handleEnableEmailClick}
            buttonText={t('profile.notifications.enableButton')}
            isLoading={isLoading}
          >
            {t('profile.notifications.enableEmail')}
          </ButtonBanner>
        </S.EnableButtonWrap>
      )}

      <S.Ul>
        {notificationState === 'ready' && (
          <S.Li>
            <span>{t('profile.notifications.push')}</span>
            <S.IconWrap isSuccess={true}>
              <CheckCircle />
            </S.IconWrap>
          </S.Li>
        )}
        {(notificationState === 'unsupported' ||
          notificationState === 'denied') && (
          <S.Li>
            <span>{t('profile.notifications.push')}</span>
            <S.IconWrap isSuccess={false}>
              <Cancel />
            </S.IconWrap>
          </S.Li>
        )}

        {(notificationState === 'unsupported' || settings?.isEmailEnabled) && (
          <S.Li>
            <span>{t('profile.notifications.email')}</span>
            <S.IconWrap isSuccess={settings?.isEmailEnabled}>
              {settings?.isEmailEnabled ? <CheckCircle /> : <Cancel />}
            </S.IconWrap>
          </S.Li>
        )}

        {settings?.isMessengerEnabled && (
          <S.Li>
            <span>{t('profile.notifications.messenger')}</span>
            <S.IconWrap isSuccess={true}>
              <CheckCircle />
            </S.IconWrap>
          </S.Li>
        )}
      </S.Ul>
    </>
  )
}

export default NotificationsSettings
