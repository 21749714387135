import { css } from 'styled-components'

type IBezierProps = [number, number, number, number]

export const easeInOutBack: IBezierProps = [0.68, -0.55, 0.265, 1.55]

export const composeEasing = (args: IBezierProps): string =>
  `cubic-bezier(${args.join(',')})`

const scaleOnHover = (scale?: number) => css`
  transition: transform 200ms ${composeEasing(easeInOutBack)};

  &:hover {
    transform: scale(${scale ? scale : 1.02});
  }
`

export const animations = {
  scaleOnHover,
}
