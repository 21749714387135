import { DocumentNode } from 'graphql'
import client, { ClientOfflineMode } from 'src/client'

// Generic API for remote graphql requests

type ApiConfig = {
  mode: ClientOfflineMode
}

// QUERY
const apiQueryRequest = async <Response, Variables = {}>(
  query: DocumentNode,
  variables?: Variables,
  config?: ApiConfig
) => {
  const result = await client.query<Response, Variables>({
    query,
    variables,
    context: { mode: config?.mode },
  })

  if (result.errors) {
    throw Error(result.errors[0].message)
  }

  return result.data
}

// MUTATION
const apiMutateRequest = async <Response, Variables = {}>(
  mutation: DocumentNode,
  variables?: Variables,
  config?: ApiConfig
) => {
  const result = await client.mutate<Response, Variables>({
    mutation,
    variables,
    context: { mode: config?.mode },
  })

  if (result.errors) {
    throw Error(result.errors[0].message)
  }

  return result.data as Response
}

// SUBSCRIPTION
const apiSubscribeRequest = async <Response, Variables = {}>(
  query: DocumentNode,
  variables?: Variables
) => {
  return client.subscribe<Response, Variables>({
    query,
    variables,
  })
}

// export as one API object
export const gqlApi = {
  apiQueryRequest,
  apiMutateRequest,
  apiSubscribeRequest,
}
