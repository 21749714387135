import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NearMe from '@material-ui/icons/NearMe'

import { useReduxDispatch } from 'src/data/store'
import { saveCurrentLocation } from 'src/data/App/actions'
import { LocationProvider } from 'src/data/App/gql/types'
import { selectLastLocation } from 'src/data/App/selectors'

import ButtonBanner from 'src/components/ButtonBanner'

type Props = {
  locationPermission?: PermissionState
  onPermissionChange?: (state: PermissionState) => void
}

/**
 * Location Handler
 * granted - the location will be saved
 * prompt - a prompt will appear
 * denied - does nothing
 *
 * Pass initial locationPermission, otherwise the component itself will ask
 * @param param0
 */
const LocationPrompt: React.FC<Props> = ({
  locationPermission,
  onPermissionChange,
}) => {
  const lastLocation = useSelector(selectLastLocation)
  const [locationState, setLocationState] = useState<
    PermissionState | undefined
  >(locationPermission || (lastLocation ? 'denied' : 'prompt'))
  const [isLocating, setIsLocating] = useState(false)

  const [t] = useTranslation()
  const dispatch = useReduxDispatch()

  useEffect(() => {
    const setStateAsync = async () => {
      if (!navigator.geolocation) {
        setLocationState('denied')
        return
      }
      if (navigator.permissions) {
        const { state } = await navigator.permissions.query({
          name: 'geolocation',
        })
        setLocationState(state)
      } else {
        // permission status is not available
        // so the user has safari of permissions are denied
        setLocationState('prompt')
      }
    }
    if (!locationPermission) {
      setStateAsync()
    }
  }, [locationPermission])

  const handleLocationRequest = useCallback(async () => {
    setIsLocating(true)
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        // on success
        (position) => {
          // save the location
          dispatch(
            saveCurrentLocation({
              coords: position.coords,
              timestamp: position.timestamp,
              provider: LocationProvider.NAVIGATOR,
            })
          )
          setIsLocating(false)
          setLocationState('granted')
          if (onPermissionChange) {
            onPermissionChange('granted')
          }
        },
        // on error
        (err) => {
          console.error(err)
          setIsLocating(false)
          setLocationState('denied')
          if (onPermissionChange) {
            onPermissionChange('denied')
          }
        }
      )
  }, [dispatch, setIsLocating, setLocationState, onPermissionChange])

  useEffect(() => {
    if (locationState === 'granted') {
      handleLocationRequest()
    }
  }, [locationState, handleLocationRequest])

  return locationState === 'prompt' ? (
    <ButtonBanner
      variant='normal'
      icon={<NearMe />}
      onClick={handleLocationRequest}
      buttonText={t('profile.location.enableButton')}
      isLoading={isLocating}
    >
      {t('profile.location.enable')}
    </ButtonBanner>
  ) : null
}

export default LocationPrompt
