import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient, DefaultOptions } from 'apollo-client'
// tslint:disable-next-line:no-implicit-dependencies
import { ApolloLink, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { RetryLink } from 'apollo-link-retry'
import QueueLink from 'apollo-link-queue'
// tslint:disable-next-line:no-implicit-dependencies
import { getMainDefinition } from 'apollo-utilities'

import { getLocalStorage, LocalStorageKeys } from 'src/utils/localStorage'
import config from 'src/config'
import { getCurrentLanguageWithoutLocale } from 'src/i18n'

// Define Apollo Cache
// The cache can be persisted by apollo-cache-persist
const cache = new InMemoryCache()

// Create an http link:
const httpLink = new HttpLink({
  uri: config.REACT_APP_HOST_URL,
})

// Create a web socket link:
const wsLink = new WebSocketLink({
  uri: config.REACT_APP_HOST_URL_WS,
  options: {
    reconnect: true,
    timeout: 300000, // Keep alive for 5 minutes
    lazy: true,
  },
})

// Split request to http or ws link based on request type
const combinedLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// retry the request in case of offline error or in case BE is down
// first attempt after 100ms, then 2x the time
const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
})

// queue offline requests and fire them when the client os online
const offlineLink = new QueueLink()
window.addEventListener('offline', () => offlineLink.close())
window.addEventListener('online', () => offlineLink.open())

// enable client offline behaviour configuration
export enum ClientOfflineMode {
  'WAIT_FOR_ONLINE' = 'WAIT_FOR_ONLINE',
  'DEFAULT' = 'DEFAULT',
}
const combinedOfflineLink = split(
  // split based on offline mode passed in context
  ({ operationName, getContext }) => {
    const { mode } = getContext()
    const shouldWait =
      mode && (mode as ClientOfflineMode) === ClientOfflineMode.WAIT_FOR_ONLINE
    if (shouldWait && !navigator.onLine) {
      console.log(operationName + ' is waiting in the queue')
    }
    return shouldWait
  },
  offlineLink, // wait with the request until the client is online
  retryLink // retry the request X times in case of error
)

// Middleware to add JWT token to headers
const authLink = new ApolloLink((operation, forward) => {
  const token = getLocalStorage(LocalStorageKeys.USER_TOKEN)
  const authHeader = token ? `Bearer ${token}` : ''
  operation.setContext(({ headers = {} }: Record<string, any>) => ({
    headers: {
      ...headers,
      'Accept-Languages': getCurrentLanguageWithoutLocale(),
      Authorization: authHeader,
    },
  }))
  return forward(operation)
})

// Options for the ApolloClient
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// Combine all together to Apollo client
const client = new ApolloClient({
  cache, // define the apollo cache
  link: ApolloLink.from([
    authLink, // add auth header
    combinedOfflineLink, // handle offline mode
    combinedLink, // combined http and ws link
  ]),
  defaultOptions,
})

export default client
