import styled from 'styled-components'
import { colors } from 'src/styles'
import { P } from 'src/styles/typography'

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 80vh;
`

export const Text = styled(P)`
  color: ${colors.primary};
`
