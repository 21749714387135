import * as firebase from 'firebase/app'
import 'firebase/messaging'

import { store } from 'src/data/store'
import { handleSendFCMToken } from 'src/data/User/thunks'
import i18n from '../i18n'

import LogoWhite from 'src/static/img/pingl_logo-white.png'

// init FCM
var config = {
  apiKey: 'AIzaSyARPQAwTA39I_b508yh1VlJILU9sndNZ5k',
  authDomain: 'pingl-customer.firebaseapp.com',
  databaseURL: 'https://pingl-customer.firebaseio.com',
  projectId: 'pingl-customer',
  storageBucket: 'pingl-customer.appspot.com',
  messagingSenderId: '1074333934101',
  appId: '1:1074333934101:web:6937b7a62356f6d490e41c',
}
let messaging = null
try {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(config)
  }
  // Retrieve Firebase Messaging object
  messaging = firebase.messaging()
  messaging.usePublicVapidKey(
    'BGWneRVjeXB1G7zlKX4XAE3wMLhr6Qwfkw8DNFDFyBfNjuTIeBQF37_Fkc3oPNDcYbUdP-JfNGbF_5x-IKganR8'
  )
} catch (e) {
  console.error(e)
}

export const firebaseMessaging = messaging

/**
 * Main firebase push notifications function
 * 1 - generate the token, ask for the persmission if needed
 * 2 - save the token on the server
 */
export const generateAndSaveFirebaseToken = async () => {
  if (!firebaseMessaging) {
    return false
  }
  try {
    const token = await firebaseMessaging.getToken()
    console.log(token)
    // save thge token using redux thunk
    const result = await store.dispatch(handleSendFCMToken({ token }))
    if (result) {
      console.log('FCM token generated. Successfully saved on the server.')
    } else {
      throw new Error('Firebase token was not saved on the server')
    }
    return true
  } catch (err) {
    console.error('Firebase token generationfailed', err)
    return false
  }
}

/**
 * Handle token expiration
 */
export const subscribeToFirebaseRefreshToken = () => {
  firebaseMessaging?.onTokenRefresh(async () => {
    await generateAndSaveFirebaseToken()
  })
}

/**
 * Handle the notifications when the client window is active
 */
export const subscribeToFirebaseNotifications = () => {
  firebaseMessaging?.onMessage((payload: NotificationPayload) => {
    console.log('onMessage', payload)
    const type = payload.data.type.toLowerCase()

    const title = i18n.t(`notifications.${type}.title`)
    const body = i18n.t(`notifications.${type}.body`)

    const options: NotificationOptions = {
      body,
      icon: LogoWhite,
    }
    // TODO: notification is not shown on Android when on foreground
    // fire the notification
    new Notification(title, options)
  })
}

type NotificationPayload = {
  collapse_key: string
  from: string
  data: {
    type: 'TEST' | 'ORDER_READY' | 'NEW_RESTAURANT'
  }
  priority: string
}
