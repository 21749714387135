// QR code types:
// table - https://example.com?id=xxxx&discount=yyy
// marketing - https://example.com?referral=name&type=takeaway_promo
// restaurant - https://example.com/yummeee

enum UrlSearchParams {
  tableId = 'id',
  discountCode = 'discount',
  referral = 'referral',
  type = 'type',
}

export const parseQrCodeUrl = (qrCodeUrl: string) => {
  const { pathname, searchParams } = new URL(qrCodeUrl)

  // if has only one pathName param, consider it as restaurantSlug
  const pathParams = pathname.substr(1).split('/')

  return {
    restaurantSlug:
      pathParams.length === 1 && pathParams[0] ? pathParams[0] : null,
    tableId: searchParams.get(UrlSearchParams.tableId),
    discountCode: searchParams.get(UrlSearchParams.discountCode),
    referral: searchParams.get(UrlSearchParams.referral),
    type: searchParams.get(UrlSearchParams.type),
  }
}

export type QRCodeParams = Partial<ReturnType<typeof parseQrCodeUrl>>
