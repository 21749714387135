import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectTagsNonEmpty } from 'src/data/Restaurant/selectors'
import Button from 'src/components/Button'

import * as S from './styled'

type Props = {
  selectedId?: string
  onSelected: (id: string) => void
  isOpen: boolean
}

const TagsFilter: React.FC<Props> = ({
  selectedId,
  onSelected: handleSelected,
  isOpen,
}) => {
  const [t] = useTranslation()
  const tags = useSelector(selectTagsNonEmpty)

  return isOpen ? (
    <S.Wrap>
      <S.SectionTitle>{t('explore.categories')}:</S.SectionTitle>
      <S.List>
        {tags.map((tag) => (
          <Button
            key={tag.id}
            variant={tag.id === selectedId ? 'primary' : 'ghost'}
            size='small'
            onClick={() => handleSelected(tag.id === selectedId ? '' : tag.id)}
          >
            {tag.name}
          </Button>
        ))}
      </S.List>
    </S.Wrap>
  ) : null
}

export default TagsFilter
