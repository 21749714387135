import styled from 'styled-components'

import { colors } from 'src/styles'
import { H1, H2 } from 'src/styles/typography'

export const Hello = styled(H2)`
  color: ${colors.gray_500};
`

export const PunchLine = styled(H1)``

export const Capitalize = styled.span`
  text-transform: capitalize;
`
