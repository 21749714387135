import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom'
import vhCheck from 'vh-check'

// pages
import MainPage from 'src/pages/MainPage'
import ProfilePage from 'src/pages/ProfilePage'
import LoginPage from 'src/pages/LoginPage'
import SignUpPage from 'src/pages/SignUpPage'
import ScannerPage from 'src/pages/ScannerPage'
import RestaurantListPage from 'src/pages/RestaurantListPage'
import MapPage from 'src/pages/MapPage'
import RestaurantPage from 'src/pages/RestaurantPage'
import OrderHistoryPage from 'src/pages/OrderHistoryPage'

import ScrollToTop from 'src/containers/ScrollToTop'
import AppRoute from 'src/containers/AppRoute'
import routes from 'src/constants/routes'

import {
  subscribeToFirebaseNotifications,
  subscribeToFirebaseRefreshToken,
} from 'src/utils/firebase'
import ErrorBoundary from 'src/containers/ErrorBoundary'

const App: React.FC = () => {
  useEffect(() => {
    // handle iphone 100vh issues
    const recalculateVh = () => {
      vhCheck()
    }
    window.addEventListener('orientationchange', recalculateVh)
    recalculateVh()
    return () => {
      window.removeEventListener('orientationchange', recalculateVh)
    }
  }, [])

  useEffect(() => {
    // handle FCM token expiration
    subscribeToFirebaseRefreshToken()
    // subscribe to notifications while the window is active
    subscribeToFirebaseNotifications()
  }, [])

  return (
    <Router>
      <ErrorBoundary>
        <ScrollToTop>
          <Switch>
            {/** Public routes */}
            <AppRoute exact={true} path={routes.index} component={MainPage} />
            <AppRoute exact={true} path={routes.login} component={LoginPage} />
            <AppRoute
              exact={true}
              path={routes.signup}
              component={SignUpPage}
            />

            <AppRoute
              exact={true}
              path={routes.scanner}
              component={ScannerPage}
            />
            <AppRoute
              exact={true}
              path={routes.explore}
              component={RestaurantListPage}
            />
            <AppRoute exact={true} path={routes.map} component={MapPage} />

            {/** Private routes */}
            <AppRoute
              exact={true}
              path={routes.profile}
              component={ProfilePage}
            />
            <AppRoute
              exact={true}
              path={routes.orderHistory}
              component={OrderHistoryPage}
            />

            {/** Restaurant detail route */}
            <AppRoute
              exact={true}
              path={routes.restaurantDetail}
              component={RestaurantPage}
            />
            {/** Try to parse example.com/everything/ as restaurant slug */}
            <Route
              exact={true}
              path='/:slug'
              render={(props) => (
                <Redirect
                  to={routes.restaurantDetailCompose(props.match.params.slug)}
                />
              )}
            />
            {/** 404 route */}
            <AppRoute component={NoMatch} />
          </Switch>
        </ScrollToTop>
      </ErrorBoundary>
    </Router>
  )
}

// 404 route
const NoMatch: React.FC = () => (
  <Redirect exact={true} to={{ pathname: routes.index }} />
)

export default App
