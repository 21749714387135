import { gqlApi } from '../gqlApi'
import { GET_SPECIAL_OFFER } from './gql/queries'
import {
  getSpecialOfferMenuItems,
  getSpecialOfferMenuItemsVariables,
} from './gql/__generated__/getSpecialOfferMenuItems'
import { ClientOfflineMode } from 'src/client'

/* QUERIES */
export const requestGetSpecialOfferMenuItems = async (
  variables: getSpecialOfferMenuItemsVariables
) => {
  return (
    await gqlApi.apiQueryRequest<
      getSpecialOfferMenuItems,
      getSpecialOfferMenuItemsVariables
    >(GET_SPECIAL_OFFER, variables, { mode: ClientOfflineMode.WAIT_FOR_ONLINE })
  ).getSpecialOfferMenuItems
}
