import styled, { css } from 'styled-components'

interface ImgProps {
  imgSize: number
}

export const Img = styled.img<ImgProps>`
  ${({ imgSize }) => css`
    width: ${imgSize}px;
    height: ${imgSize}px;
    border-radius: ${imgSize}px;
    min-width: ${imgSize}px;
  `}
`
