import React from 'react'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Payment from '@material-ui/icons/Payment'
import { useTranslation } from 'react-i18next'

import { ICreditCard } from 'src/data/User/gql/types'
import { P } from 'src/styles/typography'

import * as S from './styled'

type Props = {
  readonly creditCards: ICreditCard[]
  defaultCreditCard: string
}

const PaymentMethods: React.FC<Props> = ({
  creditCards,
  defaultCreditCard,
}) => {
  const [t] = useTranslation()
  return creditCards.length > 0 ? (
    <S.Ul>
      {creditCards.map((card) => (
        <S.Li key={card.id}>
          <span>
            <Payment />
          </span>
          <span>{card.longMaskedCln}</span>
          {card.id === defaultCreditCard && (
            <S.CheckWrap>
              <CheckCircle />
            </S.CheckWrap>
          )}
        </S.Li>
      ))}
    </S.Ul>
  ) : (
    <P variant={'small'}>{t('profile.paymentMethodsEmpty')}</P>
  )
}

export default PaymentMethods
