import { Action, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk'

import appReducer, { IAppActions } from './App/reducers'
import restaurantReducer, { IRestaurantActions } from './Restaurant/reducers'
import userReducer, { IUserActions } from './User/reducers'
import menuItemsReducer, { IMenuItemsActions } from './MenuItems/reducers'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { IGlobalActions } from './globalActions'

// thunk types
type Thunk<R> = ThunkAction<R, IReduxState, undefined, Action>
// define custom ActionCreator from "redux"
type MyActionCreator<R, P> = P extends undefined
  ? {
      (): R
    }
  : {
      (args: P): R
    }
// R  = Return type, P = params
export type TAction<R, P = undefined> = MyActionCreator<Thunk<Promise<R>>, P>

// better typed wrapper around redux useDispatch function
export type ReduxDispatch = typeof store.dispatch
export const useReduxDispatch = () => useDispatch<ReduxDispatch>()

// better typed wrapper around redux useSelector function
export const useReduxSelector: TypedUseSelectorHook<IReduxState> = useSelector

// redux types
export type IReduxState = ReturnType<typeof rootReducer>
export type IReduxActions = IGlobalActions &
  IAppActions &
  IRestaurantActions &
  IUserActions &
  IMenuItemsActions

// construct the store
const rootReducer = combineReducers({
  app: appReducer,
  restaurant: restaurantReducer,
  user: userReducer,
  menuItems: menuItemsReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer<IReduxState>(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<IReduxState, Action>)
  )
)

export const persistor = persistStore(store)
