import styled, { keyframes } from 'styled-components'

const checkmarkAnimation = keyframes`
  0% {
    stroke-dashoffset: 17px;
  }

  100% {
    stroke-dashoffset: 0;
  }
`

const circleCheckAnimation = keyframes`
  0% {
    stroke-dashoffset: 76px;
  }

  100% {
    stroke-dashoffset: 0;
  }
`

export const Group = styled.g`
  stroke-width: 2px;
  stroke: #fff;
  fill: none;

  & path {
    stroke-dasharray: 17px, 17px;
    stroke-dashoffset: 0;
    animation: ${checkmarkAnimation} 0.25s ease-in-out backwards;
    animation-delay: 0.5s;
  }

  & circle {
    stroke-dasharray: 76px, 76px;
    stroke-dashoffset: 0;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: ${circleCheckAnimation} 0.4s ease-in-out forwards;
  }
`
