import React from 'react'
import { CircularProgress } from '@material-ui/core'

import Button from '../Button'
import * as S from './styled'

type Props = {
  icon: React.ReactNode
  onClick: () => void
  buttonText: string
  variant: 'normal' | 'success' | 'error'
  isLoading?: boolean
}

const ButtonBanner: React.FC<Props> = ({
  children,
  icon,
  onClick: handleClick,
  buttonText,
  variant,
  isLoading,
}) => {
  return (
    <S.Wrap variant={variant} onClick={handleClick}>
      <S.LeftWrap>
        {icon}
        <span>{children}</span>
      </S.LeftWrap>
      {!isLoading ? (
        <Button
          variant={variant === 'normal' ? 'secondary' : variant}
          size='small'
          fullWidth={false}
        >
          {buttonText}
        </Button>
      ) : (
        <S.LoaderWrap>
          <CircularProgress size={20} />
        </S.LoaderWrap>
      )}
    </S.Wrap>
  )
}

export default ButtonBanner
