/* stylelint-disable selector-list-comma-newline-after  */
import { createGlobalStyle } from 'styled-components'
import { colors, fontFamily } from 'src/styles'

const GlobalStyle = createGlobalStyle`
  /* === CSS RESETS === */

  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    font-family: ${fontFamily.primary}, Arial, Helvetica, sans-serif;
    color: ${colors.secondary};
    line-height: 1;

    &.darken {
      background: #000;

      h1 {
        color: #dcdcdc;
      }
    }
  }

  html {
    font-size: 0.875rem;
    color: ${colors.secondary};
  }

  * {
    box-sizing: border-box;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  strong {
    font-weight: 800;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  /* style autofill inputs */
  @-webkit-keyframes autofill {
    0%,
    100% {
      color: inherit;
      background: transparent;
    }
  }

  input:-webkit-autofill {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    font-size: 16px;
  }
`
export default GlobalStyle
