import { IReduxState } from '../store'

// return the location only if it is newer than one day
export const selectCurrentLocation = (state: IReduxState) =>
  state.app.currentLocation
    ? Date.now() - state.app.currentLocation.timestamp < 1000 * 60 * 60 * 24
      ? state.app.currentLocation
      : null
    : null

export const selectLastLocation = (state: IReduxState) =>
  state.app.currentLocation
