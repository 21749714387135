import styled, { css } from 'styled-components'

type OpenProps = {
  isOpen: boolean
}

export const Wrap = styled.div<OpenProps>`
  margin-top: 22px;
  margin-right: 8px;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  transition: all 200ms;

  display: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
    `};
`
