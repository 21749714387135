import styled from 'styled-components'
import { media } from 'src/styles'

export const List = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 8px;

    ${media.phone`
      min-width: 48%;
    `}
  }

  ${media.phone`
    flex-direction: row;
    justify-content: space-between;
  `}
`

export const HeaderWrap = styled.div`
  margin-top: 64px;

  ${media.phone`
    margin-bottom: 32px;
  `}
`

export const TextWrap = styled.div``

export const SectionWrap = styled.div`
  margin: 8px 0;

  ${media.phone`
    margin: 32px 0;
  `}
`
