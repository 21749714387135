import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { media } from 'src/styles'

export const LayoutInnerWrap = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  /* stylelint-disable-next-line length-zero-no-unit */
  min-height: calc(100vh - var(--vh-offset, 0px));
`

export const GridWithImage = styled(Grid)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const MobileOnlyWrap = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 8px;
  z-index: 10000;

  ${media.phone`
    display: none;
  `}
`

export const TabletOnlyWrap = styled.div`
  display: none;
  ${media.phone`
    display: block;
  `}
`

export const GridFixedColumn = styled(({ ...rest }) => <Grid {...rest} />)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;

  ${media.phone`
    background: none;
    position: initial;
  `}
`

export const GridBottomColumn = styled(({ ...rest }) => <Grid {...rest} />)`
  border-radius: 20px 20px 0 0;
  margin-top: 80vh !important;
  margin-top: calc(80vh - 20px) !important;
  padding: 20px 8px;
  position: relative;
  z-index: 1000;

  ${media.phone`
    padding: 8px;
    min-height: 100vh;
    margin-top: 0!important;
    border-radius: 0;
  `}
`

export const BottomHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;

  svg {
    transform: rotate(90deg);
  }

  ${media.phone`
    margin-top: 16px;

    svg {
      display: none;
    }
  `}
`
