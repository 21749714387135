export const colors = {
  // main colors
  primary: '#fff',
  secondary: '#30323C',

  // theme colors
  alpha: '#F4282D',
  alpha_dark: '#D21A1F',

  // state colors
  success: '#37d773',
  success_light: '#dff7e8',
  success_dark: '#0da245',
  error: '#F4282D',
  error_light: '#fef3f3',
  error_dark: '#D21A1F',

  // shades
  gray_050: '#F7F7F7',
  gray_100: '#E0E0E0',
  gray_500: '#808080',
}

// TODO implement dark mode with bg color: #080b13
