import React from 'react'
import { useTranslation } from 'react-i18next'

import { appLanguagesArray } from 'src/i18n'
import Czech from 'src/static/SVG/Flags/Czech'
import English from 'src/static/SVG/Flags/English'

import * as S from './styled'

const mapLanguageToIcon = (lang: string) => {
  switch (lang) {
    case 'cs':
      return <Czech />
    case 'en':
      return <English />
    default:
      break
  }
}

const LanguageSwitch: React.FC = () => {
  const [, i18n] = useTranslation()

  const handleLanguageChange = (languageKey: string) => {
    i18n.changeLanguage(languageKey)
  }

  return (
    <S.Wrap>
      {appLanguagesArray.map((language, index) => (
        <S.LangButton
          key={`${language}_${index}`}
          onClick={() => handleLanguageChange(language)}
          isSelected={i18n.language === language}
          disabled={i18n.language === language}
        >
          {mapLanguageToIcon(language)}
        </S.LangButton>
      ))}
    </S.Wrap>
  )
}

export default LanguageSwitch
