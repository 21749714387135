import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const MapError: React.FC = () => {
  const [t] = useTranslation()
  const text = navigator.onLine
    ? t('map.errors.general')
    : t('map.errors.offline')
  return (
    <S.Wrap>
      <S.Text>{text}</S.Text>
    </S.Wrap>
  )
}

export default MapError
