import React, { useState, useCallback } from 'react'
import Slider, { Settings } from 'react-slick'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import * as S from './styled'
import { breakpoints } from 'src/styles/dimensions'

type Props = {
  title?: string
  slides: Array<{ element: React.ReactNode; key: string }>
  columns?: number
  smColumns?: number
  mdColumns?: number
}

const Carousel: React.FC<Props> = ({
  title,
  slides,
  columns = 1,
  smColumns,
  mdColumns,
}) => {
  const [dragging, setDragging] = useState(false)

  // how to stop click propagating if the user is dragging inspired by
  // https://github.com/akiran/react-slick/issues/848#issuecomment-597185462
  const handleBeforeChange = useCallback(() => {
    setDragging(true)
  }, [setDragging])

  const handleAfterChange = useCallback(() => {
    setDragging(false)
  }, [setDragging])

  const handleOnItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (dragging) {
        e.stopPropagation()
        e.preventDefault()
      }
    },
    [dragging]
  )

  const settings: Settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: mdColumns || smColumns || columns,
    slidesToScroll: Math.round(columns / 2),
    touchThreshold: 20,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    // this is not "mobile first", so be carefull here
    responsive: [
      // desktop
      {
        breakpoint: breakpoints.desktop,
        settings: {
          arrows: false,
        },
      },
      // tablet and phone
      ...(smColumns || columns
        ? [
            {
              breakpoint: breakpoints.tablet,
              settings: {
                arrows: false,
                touchThreshold: 10,
                slidesToShow: smColumns || columns,
                slidesToScroll: Math.round(smColumns || columns / 2),
              },
            },
          ]
        : []),
      // phone only
      ...(columns
        ? [
            {
              breakpoint: breakpoints.phone,
              settings: {
                arrows: false,
                touchThreshold: 10,
                slidesToShow: columns,
                slidesToScroll: columns,
              },
            },
          ]
        : []),
    ],
  }

  return (
    <S.Wrap>
      {title && <S.Title>{title}</S.Title>}
      <S.SliderWrap>
        <Slider {...settings}>
          {slides.map((slide) => (
            <S.Slide key={slide.key} onClickCapture={handleOnItemClick}>
              {slide.element}
            </S.Slide>
          ))}
        </Slider>
      </S.SliderWrap>
    </S.Wrap>
  )
}

export default Carousel
