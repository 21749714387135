import styled from 'styled-components'
import { H3, P } from 'src/styles/typography'
import { animations, radius, colors, media } from 'src/styles'

export const Wrap = styled.div`
  min-height: 100px;
  padding: 16px 32px;

  border-radius: ${radius.primary};
  background: ${colors.alpha};
  background: linear-gradient(
    5deg,
    ${colors.alpha_dark} 10%,
    ${colors.alpha} 70%
  );

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin: 8px 0;

  ${media.phone`
    margin: 32px 0;
    padding: 8px 32px;
    flex-direction: row;
  `}
`

export const Title = styled(H3)`
  color: #fff;
`

export const Text = styled(P)`
  color: #fff;
`

export const ActionButton = styled.button`
  padding: 16px 32px;
  border: 0;
  border-radius: ${radius.primary};

  background: ${colors.primary};
  color: ${colors.secondary};
  font-size: 1.1rem;
  cursor: pointer;

  ${animations.scaleOnHover()};

  margin-top: 16px;

  ${media.phone`
    margin-top: 0;
    font-size: 1.3rem;
  `}
`
