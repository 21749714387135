import {
  SIGN_IN,
  EMAIL_SIGN_UP,
  SEND_FCM_TOKEN,
  SET_EMAIL_NOTIF_ENABLED,
} from './gql/mutations'
import { GET_CURRENT_USER, REFRESH_TOKEN } from './gql/queries'
import { gqlApi } from '../gqlApi'
import { signIn, signInVariables } from './gql/__generated__/signIn'
import { signUp, signUpVariables } from './gql/__generated__/signUp'
import {
  sendFCMToken,
  sendFCMTokenVariables,
} from './gql/__generated__/sendFCMToken'
import { getCurrentUser } from './gql/__generated__/getCurrentUser'
import { refreshToken } from './gql/__generated__/refreshToken'
import {
  setPersonNotificationsIsEmailEnabled,
  setPersonNotificationsIsEmailEnabledVariables,
} from './gql/__generated__/setPersonNotificationsIsEmailEnabled'

// QUERIES
export const requestCurrentUser = async () => {
  return (await gqlApi.apiQueryRequest<getCurrentUser>(GET_CURRENT_USER))
    .getCurrentUser
}

export const requestRefreshToken = async () => {
  return (await gqlApi.apiQueryRequest<refreshToken>(REFRESH_TOKEN))
    .refreshToken
}

// MUTATIONS
export const requestSignIn = async (variables: signInVariables) => {
  return (
    await gqlApi.apiMutateRequest<signIn, signInVariables>(SIGN_IN, variables)
  ).signIn
}

export const requestSignUp = async (variables: signUpVariables) => {
  return (
    await gqlApi.apiMutateRequest<signUp, signUpVariables>(
      EMAIL_SIGN_UP,
      variables
    )
  ).signUp
}

export const requestSendFCMToken = async (variables: sendFCMTokenVariables) => {
  return (
    await gqlApi.apiMutateRequest<sendFCMToken, sendFCMTokenVariables>(
      SEND_FCM_TOKEN,
      variables
    )
  ).sendFCMToken
}

export const requestEnableEmailNotifications = async (
  variables: setPersonNotificationsIsEmailEnabledVariables
) => {
  return (
    await gqlApi.apiMutateRequest<
      setPersonNotificationsIsEmailEnabled,
      setPersonNotificationsIsEmailEnabledVariables
    >(SET_EMAIL_NOTIF_ENABLED, variables)
  ).setPersonNotificationsIsEmailEnabled
}
