import styled from 'styled-components'
import { P } from 'src/styles/typography'
import { media } from 'src/styles'

export const Wrap = styled.div`
  margin: 16px 0;
`

export const SectionTitle = styled(P)`
  margin-bottom: 16px;
`

export const List = styled.div`
  display: flex;

  width: 100%;
  max-width: 100%;

  overflow-x: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    margin-left: 8px;

    &:first-child {
      margin-left: 0 !important;
    }

    ${media.phone`
      margin-left: 16px;
    `}
  }
`

export const Item = styled.div``
