import i18next from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { LocalStorageKeys } from 'src/utils/localStorage'

import common_cs from 'src/static/locales/cs/common.json'
import errors_cs from 'src/static/locales/cs/errors.json'

import common_en from 'src/static/locales/en/common.json'
import errors_en from 'src/static/locales/en/errors.json'

export const getCurrentLanguageWithoutLocale = () => {
  return i18next.language.split('-')[0]
}

// map languages to json files (namespaces)
export const appLanguagesResources = {
  cs: {
    common: common_cs,
    errors: errors_cs,
  },
  en: {
    common: common_en,
    errors: errors_en,
  },
}

// export only languages, ["cs", "en"]
export const appLanguagesArray: string[] = Object.keys(appLanguagesResources)

console.log('appLanguagesResources', appLanguagesResources)
console.log('appLanguagesArray', appLanguagesArray)

// setup i18next
i18next
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: ['localStorage'],
      lookupLocalStorage: LocalStorageKeys.I18NEXT_LANG,
    },
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: 'en',
    ns: ['common', 'error'],
    defaultNS: 'common',
    fallbackNS: 'common',
    resources: appLanguagesResources,
    load: 'languageOnly',
    whitelist: appLanguagesArray,
  })

export default i18next
