import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AccountCircleSharp from '@material-ui/icons/AccountCircleSharp'
import { IconButton } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import PinglLogo from 'src/static/SVG/PinglLogo'
import routes from 'src/constants/routes'

import { selectUser } from 'src/data/User/selectors'
import { getLocalStorage, LocalStorageKeys } from 'src/utils/localStorage'
import * as S from './styled'

type Props = {
  inverted?: boolean
  showBackButton: boolean
  showProfileButton: boolean
}

const Header: React.FC<Props> = ({
  showBackButton,
  showProfileButton,
  inverted = false,
}) => {
  const [t] = useTranslation()
  const history = useHistory()

  const user = useSelector(selectUser)

  const handleBackClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (history.length > 2) {
      event.preventDefault()
      history.goBack()
    }
  }

  return (
    <S.Header inverted={inverted}>
      <div>
        {showBackButton && (
          <S.BackLink
            to={routes.index}
            replace={true}
            onClick={handleBackClick}
          >
            <IconButton>
              <ArrowBack />
            </IconButton>
          </S.BackLink>
        )}
      </div>
      <Link to={routes.index} aria-label='Pingl'>
        <PinglLogo inverted={inverted} />
      </Link>
      <div>
        {showProfileButton &&
          (user ? (
            <Link to={routes.profile}>
              <IconButton>
                <AccountCircleSharp />
              </IconButton>
            </Link>
          ) : getLocalStorage(LocalStorageKeys.LAST_LOGIN_DATE) ? (
            <Link to={routes.login}>{t('profile.login')}</Link>
          ) : (
            <Link to={routes.signup}>{t('profile.signup')}</Link>
          ))}
      </div>
    </S.Header>
  )
}

export default Header
