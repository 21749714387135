import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectRestaurants } from 'src/data/Restaurant/selectors'
import { IRestaurant } from 'src/data/Restaurant/gql/types'
import ListRestaurants from 'src/components/ListRestaurants'

import { MapState } from '..'

import * as S from './styled'
import { sortRestaurantsByDistance } from 'src/utils/locationUtils'

type Props = {
  mapState: MapState
}

type GroupedRestaurants = {
  visible: ImmutableArray<IRestaurant>
  hidden: ImmutableArray<IRestaurant>
}

const RestaurantList: React.FC<Props> = ({ mapState: { bounds, center } }) => {
  const [t] = useTranslation()
  const restaurants = useSelector(selectRestaurants)

  // group by if the place is visible on the map
  const grouped: GroupedRestaurants = bounds
    ? restaurants.reduce<GroupedRestaurants>(
        (acc, restaurant) => {
          const position:
            | google.maps.LatLng
            | google.maps.LatLngLiteral
            | undefined =
            restaurant.info.placeLocation.coordinates[0] &&
            restaurant.info.placeLocation.coordinates[1]
              ? {
                  lat: restaurant.info.placeLocation.coordinates[0],
                  lng: restaurant.info.placeLocation.coordinates[1],
                }
              : undefined

          const isVisible = position && bounds.contains(position)
          return isVisible
            ? { ...acc, visible: [...acc.visible, restaurant] }
            : { ...acc, hidden: [...acc.hidden, restaurant] }
        },
        { visible: [], hidden: [] }
      )
    : { visible: restaurants, hidden: [] }

  // sort by distance from the map center
  const sorted: GroupedRestaurants = !center
    ? grouped
    : {
        visible: sortRestaurantsByDistance(
          grouped.visible,
          {
            latitude: center.lat(),
            longitude: center.lng(),
          },
          { ignoreOpenState: true }
        ),
        hidden: sortRestaurantsByDistance(
          grouped.hidden,
          {
            latitude: center.lat(),
            longitude: center.lng(),
          },
          { ignoreOpenState: true }
        ),
      }

  return (
    <S.Wrap>
      {sorted.visible.length === 0 && (
        <S.EmptyList variant='small'>{t('map.emptyList')}</S.EmptyList>
      )}
      <ListRestaurants
        columns={2}
        restaurants={sorted.visible}
        askTableNuber={false}
      />

      {sorted.hidden.length > 0 && (
        <S.SectionTitle>{`${t('map.hiddenPlaces')}:`}</S.SectionTitle>
      )}
      <ListRestaurants
        columns={3}
        restaurants={sorted.hidden}
        askTableNuber={false}
        size={'small'}
      />
    </S.Wrap>
  )
}

export default RestaurantList
