import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectUser } from 'src/data/User/selectors'
import { getPartOfTheDay } from 'src/utils/timeUtils'

import * as S from './styled'

const Greeting: React.FC = () => {
  const [t] = useTranslation()
  const user = useSelector(selectUser)

  // memoized texts, so they will not change with every render
  const { helloText, punchLine } = useMemo(() => {
    const partOfTheDay = getPartOfTheDay()

    const helloText = t(`welcome.hello.${partOfTheDay}`)

    const punchLineArray = t(`welcome.punchline.${partOfTheDay}`, {
      returnObjects: true,
    }) as String[]

    const punchLine =
      punchLineArray[Math.floor(Math.random() * punchLineArray.length)]

    return { helloText, punchLine }
  }, [t])

  return (
    <div>
      <S.Hello>
        {helloText}
        {user && <S.Capitalize> {user.givenName}</S.Capitalize>}!
      </S.Hello>
      <S.PunchLine>{punchLine}</S.PunchLine>
    </div>
  )
}

export default Greeting
