import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ErrorBoundary from 'src/containers/ErrorBoundary'
import { TwoColumnPageLayout } from 'src/components/PageLayout'
import RestaurantList from './RestaurantList'
import Map from './Map'
import MapError from './MapError'

import { useReduxDispatch } from 'src/data/store'
import { handleGetRestaurants } from 'src/data/Restaurant/thunks'
import { saveCurrentLocation } from 'src/data/App/actions'
import { LocationProvider } from 'src/data/App/gql/types'
import { selectLastLocation } from 'src/data/App/selectors'

import * as S from './styled'

export type MapState = {
  bounds: google.maps.LatLngBounds | undefined
  center: google.maps.LatLng | undefined
}

const MapPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const [mapState, setMapState] = useState<MapState>({
    bounds: undefined,
    center: undefined,
  })

  const dispatch = useReduxDispatch()
  const lastLocation = useSelector(selectLastLocation)

  useEffect(() => {
    dispatch(handleGetRestaurants())
  }, [dispatch])

  const onCancel = () => {
    history.goBack()
  }

  const handleLocationSelect = (position: Position) => {
    if (!position.coords.latitude || !position.coords.longitude) {
      return
    }
    dispatch(
      saveCurrentLocation({
        coords: position.coords,
        timestamp: position.timestamp,
        provider: LocationProvider.MAP,
      })
    )
  }

  const handleMapBoundsChange = useCallback((args: MapState) => {
    setMapState(args)
  }, [])

  return (
    <TwoColumnPageLayout
      drawerContent={<RestaurantList mapState={mapState} />}
      drawerTitle={`${t('map.restaurantListTitle')}:`}
      showMobileHeader={true}
      invertedHeader={true}
    >
      <S.MapWrap>
        <ErrorBoundary fallbackUI={<MapError />}>
          <Map
            onLocationSelected={handleLocationSelect}
            defaultLocation={
              lastLocation
                ? {
                    lat: lastLocation.coords.latitude,
                    lng: lastLocation.coords.longitude,
                  }
                : undefined
            }
            onBoundsChanged={handleMapBoundsChange}
            onCancel={onCancel}
          />
        </ErrorBoundary>
      </S.MapWrap>
    </TwoColumnPageLayout>
  )
}

export default MapPage
