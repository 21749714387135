import styled from 'styled-components'
import { media, colors, radius } from 'src/styles'
import { H2 } from 'src/styles/typography'

export const Wrap = styled.div`
  height: 100vh;
  position: relative;

  ${media.phone`
    height: 100%;
  `}

  .scanner > section {
    width: 100%;
    padding-top: 0 !important;
    height: 100%;
  }

  .scanner > section > div {
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);

    width: 200px !important;
    height: 200px !important;

    border: 5px solid ${colors.alpha} !important;
    border-radius: ${radius.primary};
    box-shadow: rgba(48, 50, 60, 0.7) 0 0 0 1000px !important;

    ${media.phone`
      top: 50% !important;

      width: 250px !important;
      height: 250px !important;
    `}
  }

  .scanner--success > section > div {
    border: none !important;
    border-color: ${colors.success} !important;
    border-radius: 100% !important;
    background-color: ${colors.success};
    transition-duration: 1s;
  }

  .scanner--loading > section > div {
    border-radius: 100% !important;
    transition-duration: 0.5s;
  }
`

export const TitleWrap = styled.div`
  position: absolute;
  z-index: 100;
  top: 18%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

export const Title = styled(H2)`
  max-width: 220px;
  text-align: center;
  color: ${colors.primary};
`

export const IcoWrap = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);

  ${media.phone`
    top: 50%;
  `}
`

export const ErrorWrap = styled.div`
  position: absolute;
  top: 65%;
  left: 10%;
  right: 10%;
  z-index: 100;
  text-align: center;

  ${media.phone`
    left: 20%;
    right: 20%;
  `}
`
