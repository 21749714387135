import ObjectId from 'bson-objectid'

import {
  getLocalStorage,
  LocalStorageKeys,
  setLocalStorage,
} from './localStorage'

export const isPushNotifCapable = () => {
  return 'Notification' in window && 'PushManager' in window
}

export const isPushNotifAllowed = () => {
  return isPushNotifCapable() && Notification.permission === 'granted'
}

export const isPushNotifDenied = () => {
  return isPushNotifCapable() && Notification.permission === 'denied'
}

/**
 * Generate new device unique ID
 * @param alreadyRegisteredDevices List of already registered devices. Can be found in user.notifications.fcmDevices
 */
export const generateDeviceUid = (
  alreadyRegisteredDevices: readonly string[] | string[]
) => {
  const deviceUid = getLocalStorage(LocalStorageKeys.DEVICE_UID)
  if (deviceUid && alreadyRegisteredDevices.includes(deviceUid)) {
    // return current device id
    return deviceUid
  } else {
    // generate new device id by using BSON objectid, which is more than enought unique
    const newDeviceUid = 'WEB_' + new ObjectId().toHexString()
    setLocalStorage(LocalStorageKeys.DEVICE_UID, newDeviceUid)
    return newDeviceUid
  }
}
