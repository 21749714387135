import { IReduxState } from '../store'
import { IRestaurantMenuItem } from './gql/types'

export const selectAllSpecialOffers = (state: IReduxState) =>
  Object.keys(state.menuItems).reduce<IRestaurantMenuItem[]>(
    (acc, key) => [
      ...acc,
      ...state.menuItems[key].specialOffer.map((item) => ({
        ...item,
        restaurantId: key,
      })),
    ],
    []
  )
