import styled from 'styled-components'

import Button from 'src/components/Button'
import { H1, P } from 'src/styles/typography'

export const Wrap = styled.div``

export const Title = styled(H1)``

export const Text = styled(P)`
  margin-top: 32px;
`

export const ActionsWrap = styled.div`
  margin: 64px 0;
`

export const MainButton = styled(Button)``
