import styled, { css } from 'styled-components'
import { colors, radius } from 'src/styles'

export type StyleProps = {
  variant: 'top' | 'floating'
}

export const Wrap = styled.div<StyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 5px 8px;
  width: 100%;

  display: flex;
  align-items: center;

  color: ${colors.primary};
  background-color: ${colors.alpha}e6;
  border-radius: ${radius.primary} ${radius.primary} 0 0;

  ${({ variant }) =>
    variant === 'floating' &&
    css`
      top: 8px;
      left: auto;
      right: 8px;
      width: auto;
      border-radius: ${radius.primary};
    `}

  svg {
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }
`
