export enum EPaymentErrors {
  PaymentInitError = '1000',
  PaymentStatusError = '1001',
  PaymentNotInitiated = '1002',
  InvalidStateForReverse = '1003',
  ExpiredTimeForReverse = '1004',
  PaymentReverseError = '1005',
  PaymentCanceledByUser = '1006',
  PaymentDenied = '1007',
  PaymentRefundError = '1008',
}

export enum EOrderErrors {
  MenuItemsNotAvailable = '2000',
  EmptyOrderNotAllowed = '2001',
  InvalidSelectedOptions = '2002',
  PosProductLinkError = '2003',
}

export enum EAuthErrors {
  AccessDenied = '3000',
  InvalidToken = '3001',
  InvalidCredentials = '3004',
  InvalidGoogleId = '3005',
  InvalidFacebookId = '3006',
  AccountExists = '3007',
  HasLinkedGoogle = '3008',
  HasLinkedFacebook = '3009',
  HasPasswordAccount = '3010',
}

export enum EOtherErrors {
  GenericError = '5000',
  WeakPassword = '5001',
  LinkedSocialAccount = '5002',
  PinglIsOffline = '5003',
  InvalidRestaurantId = '5004',
  SpecialOfferItemAlreadyExists = '5005',
  LanguageAlreadyExists = '5006',
  InvalidOpenTimes = '5007',
  WrongEmailFormat = '5008',
  MenuItemUpdateFail = '5009',
  IntegrationNotEnabled = '5010',
  DotykackaApiFetchError = '5011',
  OptionsIsExtraWithWrongSelectionLimits = '5012',
  InvalidDeviceId = '5013',
  InvalidFcmToken = '5014',
  FcmError = '5015',
}

export enum ENotFoundErrors {
  PersonNotFound = '6000',
  PasswordNotFound = '6001',
  RestaurantNotFound = '6002',
  TableNotFound = '6003',
  OrderNotFound = '6004',
  OrderItemNotFound = '6005',
  MenuItemNotFound = '6006',
  CategoryNotFound = '6007',
  NotificationNotFound = '6008',
  OptionNotFound = '6009',
  PosOrderErrorNotFound = '6010',
  OptionValueNotFound = '6011',
  ItemOrderCountNotFound = '6012',
  CreditCardNotFound = '6013',
}
