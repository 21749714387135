import gql from 'graphql-tag'
import {
  RESTAURANT_FRAGMENT,
  DISCOUNT_FRAGMENT,
  TAG_FRAGMENT,
} from './fragments'

export const GET_RESTAURANTS = gql`
  query getRestaurants($filter: RestaurantFilter) {
    getRestaurants(filter: $filter) {
      ...RestaurantFragment
    }
  }
  ${RESTAURANT_FRAGMENT}
`

export const GET_RESTAURANT_BY_TABLE_ID = gql`
  query getRestaurantByTableId($tableId: String!) {
    getRestaurantByTableId(tableId: $tableId) {
      ...RestaurantFragment
    }
  }
  ${RESTAURANT_FRAGMENT}
`

export const GET_RESTAURANT_BY_URL_FRIENDLY_NAME = gql`
  query getRestaurantByUrlFriendlyName($name: String!) {
    getRestaurantByUrlFriendlyName(name: $name) {
      ...RestaurantFragment
    }
  }
  ${RESTAURANT_FRAGMENT}
`

export const GET_DISCOUNT_BY_CODE = gql`
  query getDiscountByCode($code: String!, $restaurantId: String!) {
    getDiscountByCode(code: $code, restaurantId: $restaurantId) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`

export const GET_ALL_TAGS = gql`
  query getAllTags {
    getAllTags {
      ...TagFragment
    }
  }
  ${TAG_FRAGMENT}
`
