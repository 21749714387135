export enum LocationProvider {
  NONE = 'NONE',
  NAVIGATOR = 'NAVIGATOR',
  MAP = 'MAP',
}

export interface IAppLocation {
  timestamp: number
  coords: Pick<Coordinates, 'latitude' | 'longitude' | 'accuracy'>
  provider: LocationProvider
}
