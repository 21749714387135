import gql from 'graphql-tag'
import { MENU_ITEM_FRAGMENT } from './fragments'
import { DISCOUNT_FRAGMENT } from 'src/data/Restaurant/gql/fragments'

export const GET_SPECIAL_OFFER = gql`
  query getSpecialOfferMenuItems(
    $restaurantId: String!
    $orderType: OrderType!
    $discountId: String
  ) {
    getSpecialOfferMenuItems(
      restaurantId: $restaurantId
      orderType: $orderType
      discountId: $discountId
    ) {
      items {
        ...MenuItemFragment
      }
      discount {
        ...DiscountFragment
      }
    }
  }
  ${MENU_ITEM_FRAGMENT}
  ${DISCOUNT_FRAGMENT}
`
