import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import routes from 'src/constants/routes'
import { TwoColumnPageLayout } from 'src/components/PageLayout'
import QrReader, { ScanResult } from './QrReader'
import RestaurantList from './RestaurantList'
import { useReduxDispatch } from 'src/data/store'
import {
  handleGetRestaurants,
  handleGetRestaurantByParams,
} from 'src/data/Restaurant/thunks'
import { QRCodeParams } from 'src/utils/qrCodeUtils'
import ErrorBoundary from 'src/containers/ErrorBoundary'

const ScannerPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useReduxDispatch()

  useEffect(() => {
    dispatch(handleGetRestaurants())
  }, [dispatch])

  const handleScanResult = async (params: QRCodeParams) => {
    try {
      const restaurant = await dispatch(handleGetRestaurantByParams(params))
      if (restaurant) {
        setTimeout(() => {
          history.push(
            routes.restaurantDetailCompose(
              restaurant.config.customer.urlFriendlyName
            )
          )
        }, 2000)
        return ScanResult.SUCCESS
      }
      return ScanResult.WRONG_CODE
    } catch (err) {
      console.error(err)
      return ScanResult.OFFLINE
    }
  }

  return (
    <TwoColumnPageLayout
      drawerTitle={`${t('scanner.restaurantListTitle')}:`}
      drawerContent={<RestaurantList />}
      showMobileHeader={true}
      invertedHeader={true}
    >
      <ErrorBoundary showFallbackUI={false}>
        <QrReader handleScanResult={handleScanResult} />
      </ErrorBoundary>
    </TwoColumnPageLayout>
  )
}

export default ScannerPage
