import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'

import { LOAD_USER_SUCCESS } from './constants'
import { IUser } from './gql/types'
import { GLOBAL_RESET_STORE } from '../globalConstants'
import { IGlobalActions } from '../globalActions'

export type IUserActions = ActionType<typeof import('./actions')>

export type IUserState = ImmutableObject<{
  user: IUser | null
}>

const initState: IUserState = {
  user: null,
}

const userReducer: Reducer<IUserState, IUserActions & IGlobalActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case LOAD_USER_SUCCESS:
      return { user: action.payload }
    case GLOBAL_RESET_STORE:
      return initState
    default:
      return state
  }
}

export default userReducer
