import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import PageLayout from 'src/components/PageLayout'
import { H1, P } from 'src/styles/typography'
import { ButtonLink } from 'src/components/Button'

import routes from 'src/constants/routes'
import {
  selectSelectedRestaurant,
  selectSelectedTableId,
  selectSelectedMenuItem,
  selectSelectedDiscount,
} from 'src/data/Restaurant/selectors'
import { useReduxDispatch } from 'src/data/store'
import { handleGetRestaurantByParams } from 'src/data/Restaurant/thunks'

type PageParams = {
  slug: string
}

const RestaurantPage: React.FC = () => {
  const { slug } = useParams<PageParams>()
  const dispatch = useReduxDispatch()
  const history = useHistory()

  const restaurant = useSelector(selectSelectedRestaurant)
  const tableId = useSelector(selectSelectedTableId)
  const menuItem = useSelector(selectSelectedMenuItem)
  const discount = useSelector(selectSelectedDiscount)

  useEffect(() => {
    const loadDataFromUrl = async () => {
      if (!restaurant || restaurant.config.customer.urlFriendlyName !== slug) {
        const data = await dispatch(
          handleGetRestaurantByParams({ restaurantSlug: slug })
        )
        if (!data) {
          history.push(routes.index)
        }
      }
    }
    loadDataFromUrl()
  }, [slug, dispatch, history, restaurant])

  const handleBackClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (history.length > 2) {
      event.preventDefault()
      history.goBack()
    }
  }

  if (!restaurant) {
    return (
      <PageLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <CircularProgress />
        </div>
      </PageLayout>
    )
  }

  const table = restaurant.tables.find((table) => table.id === tableId)

  return (
    <PageLayout>
      <H1>DONE!</H1>
      <P>This is the end, you have successfully selected this restaurant:</P>
      <br />

      <P>
        Order type: <strong>{tableId ? 'ToTable' : 'TakeAway'}</strong>
      </P>
      <br />

      <P>
        Restaurant: <strong>{restaurant.name}</strong>
        <br />
        <i style={{ opacity: 0.3 }}>ID: #{restaurant.id}</i>
      </P>
      <br />

      {table && (
        <>
          <P>
            Table: <strong>{table.name}</strong>
            <br />
            <i style={{ opacity: 0.3 }}>ID: #{table.id}</i>
          </P>
          <br />
        </>
      )}

      {menuItem && (
        <>
          <P>
            Menu item: <strong>{menuItem.name}</strong>
            <br />
            <i style={{ opacity: 0.3 }}>ID: #{menuItem.id}</i>
          </P>
          <br />
        </>
      )}

      <P>
        Discount: <strong>{discount ? discount.name : 'none'}</strong>
        <br />
        {discount && <i style={{ opacity: 0.3 }}>ID: #{discount.id}</i>}
      </P>
      <br />

      <P>
        In production ready app you will now see the menu and will be able to
        order
      </P>
      <br />
      <ButtonLink
        size='medium'
        variant='primary'
        to={routes.index}
        style={{ maxWidth: 500 }}
        onClick={handleBackClick}
      >
        Go back to restaurant selection
      </ButtonLink>
    </PageLayout>
  )
}

export default RestaurantPage
